import { DsmAccordion, DsmAccordionItem, DsmGrid, DsmIcon, DsmTabs } from "@dsm-dcs/design-system-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLayout } from "../../../contexts/layout.context";
import { routeTypes } from "../../../routes";
import PageHeader from "../../../components/pageHeader/PageHeader";
import { IResourceFile, IResourceItem } from "../../../models/resourceItem.model";
import styles from "./Support.module.scss";
import { SpeciesGroup } from "../../../models/API";

function Support() {
  //Hooks
  const { t } = useTranslation();
  const { setPageTitle, setCrumbs } = useLayout();

  //State
  const [technicalItems, setTechnicalItems] = useState<IResourceItem[]>([]);
  const [samplingFiles, setSamplingFiles] = useState<IResourceFile[]>([]);
  const [selectedSpecies, setSelectedSpecies] = useState<SpeciesGroup>(SpeciesGroup.Swine);

  //Effects
  useEffect(() => {
    setPageTitle(t("support.page.title"));
    setCrumbs([{ title: t("support.page.title"), type: routeTypes.support }]);
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [selectedSpecies]);

  //Methods
  const handleSpeciesChange = (species: SpeciesGroup) => {
    setSelectedSpecies(species);
  };

  const getItems = () => {
    setTechnicalItems(t(`support.technical-items.${selectedSpecies.toLocaleLowerCase()}`, { returnObjects: true }));
    setSamplingFiles(t("support.sampling-items", { returnObjects: true }));
  };

  return (
    <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
      <PageHeader header={t("support.page.title")}></PageHeader>
      <div className={`${styles["sub-header"]} ${styles["sub-header--first"]}`}>
        <h3>{t("support.sampling-title")}</h3>
        <span>{t("support.sampling-description")}</span>
      </div>
      {samplingFiles?.map((file) => (
        <div className={styles.file} key={file.name}>
          <DsmIcon name="files/file-04"></DsmIcon>
          <a href={file.url} target="_blank" rel="noreferrer">
            {file.name}
          </a>
          <span>
            ({file.type} - {file.size})
          </span>
        </div>
      ))}
      <div className={styles["sub-header"]}>
        <h3>{t("support.technical-title")}</h3>
        <span>{t("support.technical-description")}</span>
      </div>
      <DsmTabs className={styles.tabs}>
        {Object.keys(SpeciesGroup).map((speciesGroup) => (
          <a
            slot="navigation"
            className={`${selectedSpecies === speciesGroup ? "active" : ""}`}
            onClick={() => handleSpeciesChange(speciesGroup as SpeciesGroup)}
            key={speciesGroup}
          >
            {t(`general.species.${speciesGroup.toLocaleLowerCase()}`)}
          </a>
        ))}
      </DsmTabs>
      <DsmAccordion>
        {technicalItems.length === 0 ? <span>{t("support.no-items")}</span> : null}
        {technicalItems.map((item) => (
          <DsmAccordionItem header={item.header} key={item.header}>
            <div className={styles.files}>
              {item.files?.map((file) => (
                <div className={styles.file} key={file.name}>
                  <DsmIcon name="files/file-04"></DsmIcon>
                  <a href={file.url} target="_blank" rel="noreferrer">
                    {file.name}
                  </a>
                  <span>
                    ({file.type} - {file.size})
                  </span>
                </div>
              ))}
            </div>
          </DsmAccordionItem>
        ))}
      </DsmAccordion>
      <div className={styles["sub-header"]}>
        <h3>{t("support.general-title")}</h3>
        <span dangerouslySetInnerHTML={{ __html: t("support.general-description") }}></span>
      </div>
    </DsmGrid>
  );
}

export default Support;
