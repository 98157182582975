import { DsmButton, DsmModal, DsmIcon } from "@dsm-dcs/design-system-react";
import { useTranslation } from "react-i18next";
import styles from "./confirmModal.module.scss";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../routes";

type Props = {
  show: boolean;
};

function ConfirmModal({ show }: Props) {
  //Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();

  //Methods
  const handleOnDsmClosed = (openState: boolean): void => {
    if (!openState) {
      handleClose();
    }
  };

  const handleClose = (): void => {
    navigate(routes.home);
  };

  const handleMore = (): void => {
    navigate(0);
  };

  return (
    <DsmModal
      open={show}
      width="450px"
      header={t("sample-request-create.confirm-modal.header")}
      icon="general/check-circle"
      onDsmClosed={(e) => handleOnDsmClosed(e.detail)}
    >
      {
        <div
          className={styles.content}
          slot="content"
          dangerouslySetInnerHTML={{ __html: t("sample-request-create.confirm-modal.description") }}
        ></div>
      }
      <DsmButton variant="secondary" widthFull={true} slot="actions" className={styles["button-more"]} onClick={handleMore}>
        <DsmIcon slot="before" name="development/qr-code-02"></DsmIcon>
        {t("sample-request-create.confirm-modal.more")}
      </DsmButton>
      <DsmButton variant="primary" widthFull={true} slot="actions" onClick={handleClose}>
        <DsmIcon name="general/x-circle"></DsmIcon>
        {t("general.close")}
      </DsmButton>
    </DsmModal>
  );
}

export default ConfirmModal;
