import { DsmButton, DsmEmptyState, DsmGrid, DsmIcon, DsmLoadingIndicator, DsmTable } from "@dsm-dcs/design-system-react";
import { DsmTableData, RowActionClickEvent } from "@dsm-dcs/design-system/dist/types/types/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/pageHeader/PageHeader";
import { routeTypes, routes } from "../../../routes";
import { getLocationsForCustomer } from "../../../services/location.service";
import styles from "./FarmList.module.scss";
import { useLayout } from "../../../contexts/layout.context";
import { DsmTableCustomEvent } from "@dsm-dcs/design-system";
import { FarmListTableActions } from "../../../models/enums/actions.enum";
import { AuthContext } from "../../../contexts/auth.context";

function FarmList() {
  //Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { customer } = useContext(AuthContext);

  //State
  const [isLoadingFarms, setIsLoadingFarms] = useState<boolean>(true);
  const [farmsTable, setFarmsTable] = useState<DsmTableData>();

  //Effects
  useEffect(() => {
    setPageTitle(t("farms.page.title"));
    setCrumbs([{ title: t("farms.page.title"), type: routeTypes.farms }]);
    getInitData();
  }, []);

  //Methods
  const getInitData = async () => {
    const locations = await getLocationsForCustomer(customer?.id || "", setToast);

    setFarmsTable({
      columns: [
        { id: "name", label: t("farm.name") },
        { id: "species", label: t("farm.species") },
        { id: "location", label: t("general.location") }
      ],
      data: locations.map((_) => {
        return {
          isClickable: true,
          id: _.id || "",
          actions: [
            { type: FarmListTableActions.Results, icon: "charts/bar-chart-square-01", title: t("farms.table-actions.results") },
            { type: FarmListTableActions.Edit, icon: "editor/pencil-01", title: t("general.edit"), disabled: true },
            { type: FarmListTableActions.Delete, icon: "general/trash-01", title: t("general.delete"), destructive: true, disabled: true }
          ],
          cells: [
            { headerText: _.name || "" },
            { value: _.groups && _.groups.length > 0 ? t(`general.species.${_.groups[0].toLowerCase()}`) : "" },
            { value: `${_.address?.city}, ${_.address?.country?.name}` }
          ]
        };
      })
    });
    setIsLoadingFarms(false);
  };

  const handleTableRowClick = (e: DsmTableCustomEvent<string>) => {
    navigate(routes.farmSamples.replace(":locationId", e.detail));
  };

  const handleTableAction = (e: DsmTableCustomEvent<RowActionClickEvent>) => {
    if (e.detail.action === FarmListTableActions.Results) {
      navigate(routes.farmSamples.replace(":locationId", e.detail.id));
    }
  };

  return (
    <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
      <PageHeader
        header={t("farms.page.title")}
        description={t("farms.page.description")}
        actionsSide={
          <DsmButton onClick={() => navigate(routes.farmCreate)}>
            {t("farms.add")}
            <DsmIcon name="general/plus"></DsmIcon>
          </DsmButton>
        }
      ></PageHeader>
      <div className={styles.table}>
        {!isLoadingFarms ? (
          <>
            {farmsTable && farmsTable.data.length > 0 ? (
              <DsmTable
                data-testid="farms-table"
                data={farmsTable}
                onDsmRowClick={handleTableRowClick}
                onDsmRowActionClick={handleTableAction}
              ></DsmTable>
            ) : (
              <DsmEmptyState
                data-testid="farms-empty-state"
                header={t("farms.empty.header")}
                description={t("farms.empty.description")}
                icon="general/search-lg"
              ></DsmEmptyState>
            )}
          </>
        ) : (
          <DsmLoadingIndicator size="md"></DsmLoadingIndicator>
        )}
      </div>
    </DsmGrid>
  );
}

export default FarmList;
