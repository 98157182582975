import { useEffect, useState } from "react";
import { getUser } from "../../services/user.service";
import { Auth } from "aws-amplify";
import { DsmIcon } from "@dsm-dcs/design-system-react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { IUser } from "../../models/user.model";
import { routes } from "../../routes";
import styles from "./HeaderMenu.module.scss";

function HeaderMenu() {
  //Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  //State
  const [isSubNavOpen, setIsSubNavOpen] = useState<boolean>(true);
  const [user, setUser] = useState<IUser>();

  //Effects
  useEffect(() => {
    initData();
    document.body.addEventListener("click", closeSubNavigation);

    return () => {
      document.body.removeEventListener("click", closeSubNavigation);
    };
  }, []);

  useEffect(() => {
    setIsSubNavOpen(false);
  }, [location]);

  //Methods
  const initData = async () => {
    const user = await getUser();
    setUser(user);
  };

  const openSubNavigation = () => {
    setIsSubNavOpen(true);
  };

  const closeSubNavigation = (e: MouseEvent) => {
    if (typeof (e.target as HTMLElement)?.className === "string" && !(e.target as HTMLElement)?.className?.includes("header-menu")) {
      setIsSubNavOpen(false);
    }
  };

  const handleLogout = () => {
    Auth.signOut();
  };

  return (
    <div className={styles["header-menu"]}>
      <div className={styles["header-menu__label"]} onClick={openSubNavigation}>
        {user?.email?.[0]?.toLocaleUpperCase()}
      </div>
      <div className={`${styles["header-menu__dropdown"]} ${isSubNavOpen ? styles["header-menu__dropdown--open"] : ""}`}>
        <div className={styles["header-menu__dropdown-header"]}>
          <span>{user?.email?.[0]?.toLocaleUpperCase()}</span>
          {user?.email}
        </div>
        <a className={styles["header-menu__item"]}>
          <DsmIcon name="general/edit-02"></DsmIcon>
          {t("header.actions.edit-profile")}
        </a>
        <a className={styles["header-menu__item"]}>
          <DsmIcon name="general/settings-01"></DsmIcon>
          {t("header.actions.settings")}
        </a>
        <a
          className={styles["header-menu__item"]}
          onClick={() => {
            navigate(routes.orderStatus);
          }}
        >
          <DsmIcon name="finance-ecommerce/shopping-cart-01"></DsmIcon>
          {t("header.actions.order-history")}
        </a>
        <a className={styles["header-menu__item"]} onClick={handleLogout}>
          <DsmIcon name="general/log-out-01"></DsmIcon>
          {t("header.actions.logout")}
        </a>
      </div>
    </div>
  );
}

export default HeaderMenu;
