import { Control, Controller } from "react-hook-form";
import styles from "./SearchInput.module.scss";
import { DsmIcon } from "@dsm-dcs/design-system-react";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  fieldName: string;
  placeholder?: string;
  debounceTime?: number;
};

function SearchInput({ control, fieldName, placeholder, debounceTime }: Props) {
  const debounced = useDebouncedCallback((setValue, value) => {
    setValue(value);
  }, debounceTime ?? 500);

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange } }) => (
        <div className={styles.container}>
          <DsmIcon name="general/search-md"></DsmIcon>
          <input
            className={styles.input}
            type="text"
            placeholder={placeholder}
            onChange={(e) => debounced(onChange, e)}
            aria-label={placeholder}
          ></input>
        </div>
      )}
    />
  );
}

export default SearchInput;
