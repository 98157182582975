import { DsmButton, DsmIcon } from "@dsm-dcs/design-system-react";
import { ReactElement, useState } from "react";
import styles from "./FilterContainer.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  children: ReactElement;
  onApply: () => void;
  onClear: () => void;
};

function FilterContainer({ children, onApply, onClear }: Props) {
  //Hooks
  const { t } = useTranslation();

  //State
  const [isOpen, setIsOpen] = useState<boolean>(false);

  //Methods
  const handleApply = () => {
    onApply();
    setIsOpen(false);
  };

  const handleClear = () => {
    onClear();
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <div className="filter-container">
      <DsmButton variant="secondary" onClick={() => setIsOpen(!isOpen)}>
        {t("filter-container.button")}
        <DsmIcon slot="before" name="general/filter-lines"></DsmIcon>
      </DsmButton>
      <div className={`${styles.box} ${isOpen ? styles["box--open"] : ""}`}>
        {children}
        <div className={styles["box__buttons"]}>
          <DsmButton variant="secondary" onClick={handleClear}>
            {t("filter-container.clear")}
          </DsmButton>
          <DsmButton variant="secondary" onClick={handleCancel}>
            {t("general.cancel")}
          </DsmButton>
          <DsmButton variant="primary" onClick={handleApply}>
            {t("filter-container.apply")}
          </DsmButton>
        </div>
      </div>
    </div>
  );
}

export default FilterContainer;
