import { DsmLoadingIndicator, DsmRadioButtonGroup, DsmSelect } from "@dsm-dcs/design-system-react";
import { DsmSelectOption } from "@dsm-dcs/design-system";
import { isRequired } from "../../../helpers/validation.helper";
import { Control, Controller, FieldError } from "react-hook-form";
import { AnyObject, ObjectSchema } from "yup";
import styles from "./select.module.scss";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  fieldName: string;
  options: DsmSelectOption[];
  label?: string;
  tooltip?: string;
  schema?: ObjectSchema<AnyObject>;
  fieldError?: FieldError;
  forceRequired?: boolean;
  disabled?: boolean;
  loading?: boolean;
  useSearch?: boolean;
  emptyText?: string;
  noOptionsText?: string;
};

function Select({
  control,
  fieldName,
  options,
  label,
  tooltip,
  schema,
  fieldError,
  forceRequired,
  disabled,
  loading,
  useSearch,
  emptyText,
  noOptionsText
}: Props) {
  return (
    <div className={styles.container}>
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            {options.length === 0 && noOptionsText ? (
              <DsmRadioButtonGroup label={label}>
                <div className={styles["no-option-text"]}>{noOptionsText}</div>
              </DsmRadioButtonGroup>
            ) : (
              <DsmSelect
                data-testid={`select-${fieldName}`}
                onDsmChange={(e) => {
                  onChange(e.detail);
                  onBlur();
                }}
                onBlur={onBlur}
                value={value}
                options={options}
                label={label}
                tooltip={tooltip}
                invalid={!!fieldError}
                touched={!!fieldError}
                errorText={fieldError?.message}
                disabled={disabled}
                required={forceRequired || isRequired(schema, fieldName)}
                useSearch={useSearch}
                emptyText={emptyText}
              ></DsmSelect>
            )}
          </>
        )}
      />
      {loading ? <DsmLoadingIndicator size="sm" className={styles.loader}></DsmLoadingIndicator> : null}
    </div>
  );
}

export default Select;
