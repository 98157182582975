import i18next from "i18next";
import * as yup from "yup";
import { PhytaseUnit } from "../enums/phytaseUnit.enum";

export interface EditSampleRequestForm {
  d3Level?: number | null;
  ohLevel?: number | null;
  caLevel?: number | null;
  pTotal?: number | null;
  phytase?: number | null;
  phytaseUnit?: string | null;
  geneticSupplier?: string | null;
  geneticSupplierOther?: string;
  geneticLineage?: string | null;
  geneticLineageOther?: string;
}

export const getEditSampleRequestFormSchema = () => {
  return yup.object({
    d3Level: yup
      .number()
      .required()
      .typeError(i18next.t("form.errors.number", { label: i18next.t("sample-request.d3-level") }))
      .min(0)
      .label(i18next.t("sample-request.d3-level")),
    ohLevel: yup
      .number()
      .required()
      .typeError(i18next.t("form.errors.number", { label: i18next.t("sample-request.oh-level") }))
      .min(0)
      .label(i18next.t("sample-request.oh-level")),
    caLevel: yup
      .number()
      .notRequired()
      .typeError(i18next.t("form.errors.number", { label: i18next.t("sample-request.ca-level") }))
      .min(0)
      .label(i18next.t("sample-request.ca-level")),
    pTotal: yup
      .number()
      .notRequired()
      .typeError(i18next.t("form.errors.number", { label: i18next.t("sample-request.p-total") }))
      .min(0)
      .label(i18next.t("sample-request.p-total")),
    phytase: yup
      .number()
      .notRequired()
      .typeError(i18next.t("form.errors.number", { label: i18next.t("sample-request.phytase") }))
      .min(0)
      .label(i18next.t("sample-request.phytase")),
    phytaseUnit: yup
      .mixed<PhytaseUnit>()
      .label(i18next.t("sample-request.phytase-unit"))
      .when("phytase", {
        is: (phytase: number) => phytase && phytase >= 0,
        then: (schema) => schema.oneOf(Object.values(PhytaseUnit)).required()
      }),
    geneticSupplier: yup.string().required().label(i18next.t("sample-request.genetic-supplier")),
    geneticSupplierOther: yup
      .string()
      .label(i18next.t("sample-request.genetic-supplier-other"))
      .when("geneticSupplier", {
        is: (supplier: string) => supplier === "Other",
        then: (schema) => schema.required()
      }),
    geneticLineage: yup.string().required().label(i18next.t("sample-request.genetic-lineage")),
    geneticLineageOther: yup
      .string()
      .label(i18next.t("sample-request.genetic-lineage-other"))
      .when("geneticLineage", {
        is: (lineage: string) => lineage === "Other",
        then: (schema) => schema.required()
      })
  });
};
