import { Genetic, Phase } from "../models/API";
import { DsmSelectOption } from "@dsm-dcs/design-system";

export const getLineageOptions = (phase: Phase): DsmSelectOption[] => {
  return (
    phase.geneticLineage
      ?.filter((_): _ is Genetic => _ !== null)
      .map((lineage: Genetic) => ({ value: lineage.name ?? "", text: lineage.name ?? "" })) ?? []
  );
};

export const getSupplierOptionsOptions = (phase: Phase): DsmSelectOption[] => {
  return (
    phase.geneticSupplier
      ?.filter((_): _ is Genetic => _ !== null)
      .map((supplier: Genetic) => ({ value: supplier.name ?? "", text: supplier.name ?? "" })) ?? []
  );
};
