import { Dispatch, SetStateAction } from "react";
import { useOutletContext } from "react-router-dom";
import { IBreadcrumb } from "../models/breadcrumb.model";
import { IToast } from "../models/toast.model";

type LayoutContextProps = {
  setPageTitle: Dispatch<SetStateAction<string>>;
  setCrumbs: Dispatch<SetStateAction<IBreadcrumb[]>>;
  setToast: Dispatch<SetStateAction<IToast | null>>;
};

export function useLayout() {
  return useOutletContext<LayoutContextProps>();
}
