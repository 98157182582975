import { useEffect, useState } from "react";

export enum Breakpoints {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl"
}

const useBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState("");
  const [windowWidth, setWindowWidth] = useState<number>(0);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    if (windowWidth < 640) {
      setBreakPoint(Breakpoints.xs);
    } else if (windowWidth < 768) {
      setBreakPoint(Breakpoints.sm);
    } else if (windowWidth < 1024) {
      setBreakPoint(Breakpoints.md);
    } else if (windowWidth < 1280) {
      setBreakPoint(Breakpoints.lg);
    } else {
      setBreakPoint(Breakpoints.xl);
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);
  return breakpoint;
};

export default useBreakpoint;
