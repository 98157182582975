import { ReactNode, useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/auth.context";
import { DsmLoadingIndicator } from "@dsm-dcs/design-system-react";
import { Role } from "../../models/enums/role.enum";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";

type Props = {
  children?: ReactNode;
  roles?: Role[];
  customerRequiredForManager?: boolean;
};

function RequireAuth({ children, roles, customerRequiredForManager }: Props) {
  //Hooks
  const { isLoading, isAuthorized, role, customer, handleCustomerById } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (role && roles && !roles?.includes(role)) {
      navigate(routes.home);
    } else if (role === Role.Manager || role === Role.Admin) {
      //Get customer if not loaded (for example after page refresh)
      if (!customer) {
        const customerId = sessionStorage.getItem("customerId");
        if (customerId) {
          const success = handleCustomerById(customerId);
          if (!success) {
            navigate(routes.customers);
          }
        }
      }

      if (customerRequiredForManager && !customer) {
        navigate(routes.customers);
      }
    }
  }, [role, roles, customer, customerRequiredForManager]);

  return <>{isAuthorized && !isLoading ? children : <DsmLoadingIndicator className="loading-indicator" size="lg"></DsmLoadingIndicator>}</>;
}

export default RequireAuth;
