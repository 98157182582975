import { DsmButton, DsmEmptyState, DsmGrid, DsmIcon, DsmLoadingIndicator, DsmPagination, DsmTable } from "@dsm-dcs/design-system-react";
import { DsmTableData } from "@dsm-dcs/design-system/dist/types/types/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import styles from "./OrderHistory.module.scss";
import { useLayout } from "../../../contexts/layout.context";
import { useNavigate } from "react-router-dom";
import { KitRequestTableInput, Page, SortDirection } from "../../../models/API";
import PageHeader from "../../../components/pageHeader/PageHeader";
import { routeTypes, routes } from "../../../routes";
import { getKitRequests } from "../../../services/kitRequest.service";
import { AuthContext } from "../../../contexts/auth.context";
import { Role } from "../../../models/enums/role.enum";
import { getVariantForStatus } from "../../../helpers/orderKit.helper";

function OrderHistory() {
  //Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { customer, role } = useContext(AuthContext);

  //State
  const [orderHistoryTable, setOrderHistoryTable] = useState<DsmTableData | null>(null);
  const [orderHistoryTablePages, setOrderHistoryTablePages] = useState<Page[]>([]);
  const [currentOrderHistoryTablePage, setCurrentOrderHistoryTablePage] = useState<number>(1);

  //Effects
  useEffect(() => {
    setPageTitle(t("order-history.page.title"));
    setCrumbs([{ title: t("order-history.page.title"), type: routeTypes.orders }]);
    initData();
  }, []);

  //Methods
  const initData = async () => {
    await getOrderHistoryTableData(role === Role.Customer ? customer?.id || "" : null, null, true);
  };

  const getOrderHistoryTableData = async (customerId: string | null, pageToken: string | null, isInitial: boolean) => {
    const kitRequestTableInput: KitRequestTableInput = { itemsPerPage: 10, sortDirection: SortDirection.Desc, pageToken };
    const kitRequests = await getKitRequests(customerId, kitRequestTableInput, setToast);

    const ordersTable: DsmTableData = {
      columns: [
        { id: "order", label: t("order.order") },
        { id: "farm", label: t("order.farm") },
        { id: "date", label: t("order.date") },
        { id: "amount", label: t("order.kits") },
        { id: "status", label: t("order.status") },
        { id: "trackingNumber", label: t("order.trackingNumber") }
      ],
      data:
        kitRequests?.rows?.map((kitRequest) => {
          return {
            id: kitRequest.kitRequestId || "",
            cells: [
              { headerText: kitRequest.orderNumber?.toString() || "" },
              { value: kitRequest.locationName || "" },
              { value: dayjs(kitRequest.createdDateTime || "").format(t("general.date-format")) },
              { value: kitRequest.totalAmount?.toString() || "" },
              {
                badges: kitRequest.status
                  ? [
                      {
                        value: kitRequest.status.toString(),
                        variant: getVariantForStatus(kitRequest.status)
                      }
                    ]
                  : []
              },
              { value: kitRequest.trackAndTrace || "" }
            ]
          };
        }) || []
    };
    if (role === Role.Manager || role === Role.Admin) {
      ordersTable.columns.splice(1, 0, { id: "customer", label: t("sample-request.customer") });
      kitRequests?.rows?.forEach((kitRequests, index) => {
        ordersTable.data[index].cells.splice(1, 0, { value: kitRequests.customer?.name || "" });
      });
    }
    setOrderHistoryTable(ordersTable);

    if (isInitial) {
      setOrderHistoryTablePages(kitRequests?.pages || []);
    }
  };

  const handleOrderHistoryPageChange = async (page: number) => {
    setCurrentOrderHistoryTablePage(page);
    const pageData = orderHistoryTablePages.find((_) => _.page === page - 1);
    await getOrderHistoryTableData(customer?.id || "", pageData?.token || null, false);
  };

  const handleRegisterClick = () => {
    navigate(routes.sampleRequestCreate);
  };

  const orderNewKit = () => {
    navigate(routes.order);
  };

  return (
    <>
      <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
        <div className={styles.orderStatus}>
          <PageHeader
            header={t("order-history.page.title")}
            description={t("order-history.page.description")}
            actionsSide={
              <>
                {role === Role.Admin ? (
                  <DsmButton onClick={orderNewKit}>
                    {t("home.actions.order")}
                    <DsmIcon slot="before" name="finance-ecommerce/shopping-cart-03"></DsmIcon>
                  </DsmButton>
                ) : null}
                <DsmButton onClick={handleRegisterClick}>
                  {t("home.actions.register")}
                  <DsmIcon slot="before" name="development/qr-code-02"></DsmIcon>
                </DsmButton>
              </>
            }
          ></PageHeader>
        </div>
        <div className={styles.tables}>
          <div className={styles.tables__table}>
            {orderHistoryTable ? (
              orderHistoryTable.data.length > 0 ? (
                <>
                  <DsmTable data={orderHistoryTable} data-testid="orders-table"></DsmTable>
                  {orderHistoryTablePages.length > 0 ? (
                    <DsmPagination
                      currentPage={currentOrderHistoryTablePage}
                      pageCount={orderHistoryTablePages.length}
                      onDsmChangePage={(e) => handleOrderHistoryPageChange(e.detail)}
                      data-testid="orders-pagination"
                    ></DsmPagination>
                  ) : null}
                </>
              ) : (
                <DsmEmptyState icon="charts/pie-chart-01" header={t("home.results.empty")} data-testid="orders-empty-state"></DsmEmptyState>
              )
            ) : (
              <DsmLoadingIndicator></DsmLoadingIndicator>
            )}
          </div>
        </div>
        <div></div>
      </DsmGrid>
    </>
  );
}

export default OrderHistory;
