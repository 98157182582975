import { useEffect } from "react";

function SSOProxy() {
  useEffect(() => {
    if (window.gigya) return;

    const config = {
      loginURL: "/sso-login",
      logoutURL: "/sso-logout"
    };

    const script = document.createElement("script");
    script.src = "https://cdns.gigya.com/js/gigya.saml.js?apiKey=" + process.env.REACT_APP_ADOBE_GIGYA_KEY;
    script.innerHTML = JSON.stringify(config);
    document.body.appendChild(script);
  });

  return <div></div>;
}

export default SSOProxy;
