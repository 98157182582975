// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRequired = (schema: any, fieldName: string): boolean => {
  if (!schema) {
    return false;
  }

  const fields = fieldName.split(".");
  let fieldSchema = schema.fields[fields[0]];

  if (fields.length > 1) {
    fieldSchema = fieldSchema.innerType.fields[fields[1]];
  }
  return !fieldSchema?.spec?.optional;
};
