import {
  DsmAlert,
  DsmGrid,
  DsmLoadingIndicator,
  DsmButton,
  DsmButtonGroup,
  DsmButtonGroupItem,
  DsmIcon,
  DsmTable,
  DsmInput
} from "@dsm-dcs/design-system-react";
import PageHeader from "../../../components/pageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import ResultList from "../../../components/resultList/ResultList";
import styles from "./SampleRequestRead.module.scss";
import Plot from "react-plotly.js";
import { useParams } from "react-router-dom";
import { editSampleRequest, getSampleResult } from "../../../services/sample.service";
import { SampleRequest, SampleResults } from "../../../models/API";
import { toFixedNumber } from "../../../helpers/number.helper";
import dayjs from "dayjs";
import { useLayout } from "../../../contexts/layout.context";
import { routeTypes, routes } from "../../../routes";
import {
  calculateOhLevelIUKG,
  calculateTotalVitamineD3,
  getAverageText,
  getAverageVariant,
  getScatterCss
} from "../../../helpers/sampleData.helper";
import useDetectPrint from "../../../hooks/print.hook";
import { ProblemArea, SpecificPhase, SpecificProblemArea } from "../../../models/testReason.model";
import { ProductionSystemSwine } from "../../../models/enums/productionSystem.enum";
import { DsmCDNUrl } from "../../../config";
import { IResultList } from "../../../models/resultList.model";
import { Role } from "../../../models/enums/role.enum";
import { AuthContext } from "../../../contexts/auth.context";
import NumberInput from "../../../components/form/numberInput/NumberInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "../../../components/form/select/Select";
import { PhytaseUnit } from "../../../models/enums/phytaseUnit.enum";
import { DsmSelectOption, DsmTableData } from "@dsm-dcs/design-system";
import { getEditSampleRequestFormSchema } from "../../../models/forms/edit-sample-request.model";
import { getLineageOptions, getSupplierOptionsOptions } from "../../../helpers/phase.helper";
import Input from "../../../components/form/input/Input";

enum ResultViewType {
  Graph,
  Average,
  Table
}

function SampleRequestRead() {
  //Hooks
  const { sampleID } = useParams();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { t } = useTranslation();
  const isPrinting = useDetectPrint();
  const { customer, role } = useContext(AuthContext);

  //State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sampleRequest, setSampleRequest] = useState<SampleRequest | null>();
  const [problem, setProblem] = useState<string>("other");
  const [resultList, setResultList] = useState<IResultList | null>(null);
  const [resultValues, setResultValues] = useState<number[]>([]);
  const [breakdownValues, setBreakdownValues] = useState<number[]>([]);
  const [resultViewType, setResultViewType] = useState<ResultViewType>(ResultViewType.Graph);
  const [scatterCSS, setScatterCSS] = useState<string>("");
  const [shouldShowLevels, setShouldShowLevels] = useState<boolean>(true);
  const [showFullData, setShowFullData] = useState<boolean>(false);
  const [samplesTable, setSamplesTable] = useState<DsmTableData>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [geneticSupplierOptions, setGeneticSupplierOptions] = useState<DsmSelectOption[]>([]);
  const [geneticLineageOptions, setGeneticLineageOptions] = useState<DsmSelectOption[]>([]);
  const [phytaseUnitOptions, setPhytaseUnitOptions] = useState<DsmSelectOption[]>([]);
  const [schema] = useState(getEditSampleRequestFormSchema());
  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema)
  });
  const geneticSupplier = watch("geneticSupplier");
  const geneticLineage = watch("geneticLineage");
  const ohLevel = watch("ohLevel");
  const d3Level = watch("d3Level");

  //Effects
  useEffect(() => {
    setPageTitle("");
    getInitData();
  }, []);

  useEffect(() => {
    if (!sampleRequest) {
      return;
    }

    setPageTitle(sampleRequest.name || "");
    const showLevels = !!sampleRequest.phase?.adequate || !!sampleRequest.phase?.insufficient || !!sampleRequest.phase?.deficient;
    const results = sampleRequest.samples?.map((_) => _.results?.[0]?.result || 0) || [];
    setResultValues(results);
    setShouldShowLevels(showLevels);
    setTimeout(() => {
      const leftMargin = document.querySelector(".xzl")?.attributes.getNamedItem("transform")?.value.match(/\d+/)?.[0] ?? "0";
      setScatterCSS(showLevels ? getScatterCss(sampleRequest?.phase, results, leftMargin) : "");
    });

    const tableData: DsmTableData = {
      columns: [
        { id: "result", label: t("sample-request-read.individual-results-table.result") },
        { id: "animal", label: t("sample-request.animal") }
      ],
      data:
        sampleRequest?.samples?.map((_) => {
          return {
            id: _.id || "",
            cells: [
              {
                headerText: `${_.results?.[0]?.result || "0"}${hasRemark(_.results?.[0]) ? "*" : ""} ng/mL`
              },
              { value: _.animalId || "" }
            ]
          };
        }) || []
    };

    if (showLevels) {
      tableData.columns.splice(1, 0, {
        id: "performance",
        label: t("sample-request-read.individual-results-table.performance")
      });

      tableData.columns.splice(2, 0, { id: "card", label: t("sample-request.card") });

      sampleRequest?.samples?.forEach((_, i) => {
        tableData.data[i].cells.splice(1, 0, {
          badges: [
            {
              value: getAverageText(sampleRequest.phase, _.results?.[0]?.result),
              variant: getAverageVariant(sampleRequest.phase, _.results?.[0]?.result)
            }
          ]
        });

        tableData.data[i].cells.splice(2, 0, { value: _.cardId || "" });
      });
    }

    const shouldShowParity = sampleRequest?.samples?.some((_) => _.parity);
    if (shouldShowParity) {
      tableData.columns.push({ id: "parity", label: t("sample-request.parity") });
      sampleRequest?.samples?.forEach((_, i) => {
        tableData.data[i].cells.push({ value: _.parity || "" });
      });
    }

    if (sampleRequest?.specificProblemArea) {
      tableData.columns.push({
        id: "problemAnswer",
        label: t(`general.test-reason-problem.${sampleRequest?.problem?.toLocaleLowerCase()}`)
      });
      sampleRequest?.samples?.forEach((_, i) => {
        tableData.data[i].cells.push({
          value:
            _.problemAnswer === true
              ? t("general.yes-no.yes")
              : _.problemAnswer === false
              ? t("general.yes-no.no")
              : t("general.yes-no.unknown")
        });
      });
    }

    const shouldShowRemark = sampleRequest?.samples?.some((_) => _.results?.[0]?.result && _.results?.[0]?.result < 10);
    if (shouldShowRemark) {
      tableData.columns.push({ id: "remark", label: t("sample-request.remark") });
      sampleRequest?.samples?.forEach((_, i) => {
        const remark = getRemark(_.results?.[0]);
        tableData.data[i].cells.push({ value: remark });
      });
    }

    setSamplesTable(tableData);
  }, [sampleRequest]);

  useEffect(() => {
    handleCrumbs();
  }, [customer, sampleRequest, isLoading]);

  useEffect(() => {
    if (!resultValues.length) {
      return;
    }

    setBreakdownValues(calculateBreakdown(resultValues));
  }, [resultValues]);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [isPrinting]);

  useEffect(() => {
    if (!breakdownValues.length) {
      return;
    }

    const resultList: IResultList = {
      valueHeader: t("general.result-description.value-unit"),
      descriptionHeader: t("general.result-description.description"),
      data: []
    };

    if (sampleRequest?.phase?.optimum) {
      resultList.data.push({
        type: "optimum",
        value: breakdownValues[3],
        range: `>${sampleRequest?.phase?.adequate ?? sampleRequest?.phase?.insufficient} ${t("general.unit-25ohd3")}`,
        header: `${t("general.result-breakdown.optimum")}: >${sampleRequest?.phase?.adequate ?? sampleRequest?.phase?.insufficient} ${t(
          "general.unit-of-25ohd3"
        )}`,
        description: t("general.result-description.optimum")
      });
    }
    if (sampleRequest?.phase?.adequate) {
      resultList.data.push({
        type: "adequate",
        value: breakdownValues[2],
        range: `${sampleRequest?.phase?.insufficient}-${sampleRequest?.phase?.adequate} ${t("general.unit-25ohd3")}`,
        header: `${t("general.result-breakdown.adequate")}: ${sampleRequest?.phase?.insufficient}-${sampleRequest?.phase?.adequate} ${t(
          "general.unit-of-25ohd3"
        )}`,
        description: t("general.result-description.adequate")
      });
    }
    if (sampleRequest?.phase?.insufficient) {
      resultList.data.push({
        type: "insufficient",
        value: breakdownValues[1],
        range: `${sampleRequest?.phase?.deficient}-${sampleRequest?.phase?.insufficient} ${t("general.unit-25ohd3")}`,
        header: `${t("general.result-breakdown.insufficient")}: ${sampleRequest?.phase?.deficient}-${sampleRequest?.phase
          ?.insufficient} ${t("general.unit-of-25ohd3")}`,
        description: t("general.result-description.insufficient")
      });
    }

    if (sampleRequest?.phase?.deficient) {
      resultList.data.push({
        type: "deficient",
        value: breakdownValues[0],
        range: `<${sampleRequest?.phase?.deficient} ${t("general.unit-25ohd3")}`,
        header: `${t("general.result-breakdown.deficient")}: <${sampleRequest?.phase?.deficient} ${t("general.unit-of-25ohd3")}`,
        description: t("general.result-description.deficient")
      });
    }

    setResultList(resultList);
  }, [breakdownValues]);

  useEffect(() => {
    setValue("d3Level", sampleRequest?.d3Level ?? 0);
    setValue("ohLevel", sampleRequest?.samples?.[0]?.dosage ?? 0);
    setValue("caLevel", sampleRequest?.samples?.[0]?.feedComposition?.caLevel);
    setValue("pTotal", sampleRequest?.samples?.[0]?.feedComposition?.pLevel);
    setValue("phytase", sampleRequest?.samples?.[0]?.feedComposition?.phytase ?? null);
    setValue("phytaseUnit", (sampleRequest?.samples?.[0]?.feedComposition?.phytaseUnit as PhytaseUnit) ?? null);

    const geneticLineage = sampleRequest?.samples?.[0]?.geneticLineage ?? "";
    if (!geneticLineage) {
      setValue("geneticLineage", "");
      setValue("geneticSupplierOther", "");
    } else if (sampleRequest?.phase?.geneticLineage?.some((supplier) => supplier?.name === geneticLineage)) {
      setValue("geneticLineage", geneticLineage);
    } else {
      setValue("geneticLineage", "Other");
      setValue("geneticLineageOther", geneticLineage);
    }

    const geneticSupplier = sampleRequest?.samples?.[0]?.geneticSupplier ?? "";
    if (!geneticSupplier) {
      setValue("geneticSupplier", "");
      setValue("geneticSupplierOther", "");
    } else if (sampleRequest?.phase?.geneticSupplier?.some((supplier) => supplier?.name === geneticSupplier)) {
      setValue("geneticSupplier", geneticSupplier);
    } else {
      setValue("geneticSupplier", "Other");
      setValue("geneticSupplierOther", geneticSupplier);
    }
  }, [sampleRequest]);

  useEffect(() => {
    setIsEdit(false);
  }, [showFullData]);

  //Methods
  const isResultsPending = (): boolean => {
    return !sampleRequest?.average && sampleRequest?.average === 0;
  };

  const getInitData = async () => {
    const sampleRequestData = await getSampleResult(sampleID || "", setToast);
    setProblem(sampleRequestData?.problem?.toLocaleLowerCase() ?? "other");
    setSampleRequest(sampleRequestData);

    if (sampleRequestData?.phase?.geneticSupplier && sampleRequestData?.phase?.geneticSupplier.length > 0) {
      const options = getSupplierOptionsOptions(sampleRequestData?.phase);
      setGeneticSupplierOptions(options);
    }

    if (sampleRequestData?.phase?.geneticLineage && sampleRequestData?.phase?.geneticLineage.length > 0) {
      const options = getLineageOptions(sampleRequestData?.phase);
      setGeneticLineageOptions(options);
    }

    setPhytaseUnitOptions(
      Object.keys(PhytaseUnit).map((phytaseUnit) => {
        return { value: phytaseUnit, text: t(`general.phytase-unit.${phytaseUnit.toLocaleLowerCase()}`) };
      })
    );

    setIsLoading(false);
  };

  const handleCrumbs = () => {
    const startCrumbs = [];
    if (role === Role.Manager || role === Role.Admin) {
      startCrumbs.push(
        { title: t("customers.page.title"), url: routes.customers, type: routeTypes.customers },
        { title: customer?.name ?? "...", url: routes.customers, type: routeTypes.customerSelected }
      );
    } else {
      startCrumbs.push({ title: t("farms.page.title"), url: routes.farms, type: routeTypes.farms });
    }

    if (isLoading) {
      setCrumbs([...startCrumbs, { title: "...", type: routeTypes.loading }, { title: "...", type: routeTypes.loading }]);
    } else {
      setCrumbs([
        ...startCrumbs,
        {
          title: sampleRequest?.location?.name || "",
          url: routes.farmSamples.replace(":locationId", sampleRequest?.location?.id || ""),
          type: routeTypes.farmSamples
        },
        { title: sampleRequest?.name || "", type: routeTypes.farmSample }
      ]);
    }
  };

  const getMarkerColorForValue = (value: number): string => {
    if (!shouldShowLevels) {
      return "#000";
    } else if (value < (sampleRequest?.phase?.deficient || 0)) {
      return "#d92d20";
    } else if (value < (sampleRequest?.phase?.insufficient || 0)) {
      return "#f08b1f";
    } else if (sampleRequest?.phase?.adequate && value < (sampleRequest?.phase?.adequate || 0)) {
      return "#44b4a1";
    } else {
      return "#084337";
    }
  };

  const calculateBreakdown = (resultValuesData: number[]) => {
    let deficient = 0;
    let insufficient = 0;
    let adequate = 0;
    let optimum = 0;
    resultValuesData.forEach((value) => {
      if (value < (sampleRequest?.phase?.deficient || 0)) {
        deficient++;
      } else if (value < (sampleRequest?.phase?.insufficient || 0)) {
        insufficient++;
      } else if (value < (sampleRequest?.phase?.adequate || 0)) {
        adequate++;
      } else {
        optimum++;
      }
    });
    const sampleAmount = sampleRequest?.samples?.length || 0;
    return [
      toFixedNumber((deficient / sampleAmount) * 100, 1),
      toFixedNumber((insufficient / sampleAmount) * 100, 1),
      toFixedNumber((adequate / sampleAmount) * 100, 1),
      toFixedNumber((optimum / sampleAmount) * 100, 1)
    ];
  };

  const getPhaseName = (): string => {
    return sampleRequest?.phase?.name?.split("-")[0].trim().toLowerCase() ?? "animals";
  };

  const getPhaseLevel = (level: string): string => {
    if (level === "deficient") {
      return `<${sampleRequest?.phase?.deficient?.toString() || "0"}`;
    } else if (level === "insufficient") {
      return `${sampleRequest?.phase?.deficient?.toString() || "0"}-${sampleRequest?.phase?.insufficient?.toString() || "0"}`;
    } else if (level === "adequate") {
      return `${sampleRequest?.phase?.insufficient?.toString() || "0"}-${sampleRequest?.phase?.adequate?.toString() || "0"}`;
    } else if (level === "optimum") {
      return `>${sampleRequest?.phase?.adequate?.toString() || "0"}`;
    }

    return "0";
  };

  const hasRemark = (result: SampleResults | null | undefined): boolean => {
    return result?.result !== null && result?.result !== undefined && result.result < 10;
  };

  const getRemark = (result: SampleResults | null | undefined): string => {
    if (result?.result !== null && result?.result !== undefined && result.result <= 5) {
      return t("sample-request.remark-lod");
    } else if (result?.result && result.result < 10) {
      return t("sample-request.remark-loq");
    }
    return "";
  };

  const handleEditButtonClick = async () => {
    if (isEdit) {
      setIsLoading(true);
      const newSampleRequest = await editSampleRequest(sampleRequest?.id || "", getValues());
      if (newSampleRequest) {
        setSampleRequest(newSampleRequest);
        setToast({ type: "success", text: t("sample-request-read.edit-success") });
      }
      setIsLoading(false);
    }

    setIsEdit(!isEdit);
  };

  return (
    <>
      <style>{scatterCSS}</style>
      <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
        <PageHeader
          header={t("sample-request-read.page.title", { name: sampleRequest?.name || "" })}
          description={t("sample-request-read.page.description")}
          actionsSide={
            !isLoading && !isResultsPending() ? <DsmButton onClick={() => window.print()}>{t("general.print-report")}</DsmButton> : null
          }
        ></PageHeader>
        {!isLoading && sampleRequest ? (
          <>
            <div className={`${styles["data-container"]} ${showFullData ? styles["data-container--open"] : ""}`}>
              <div className={styles["data-container__panel"]}>
                <DsmButton className={styles["data-container__edit"]} onClick={handleEditButtonClick}>
                  {isEdit ? <DsmIcon name="general/save-01"></DsmIcon> : <DsmIcon name="general/edit-02"></DsmIcon>}
                  {isEdit ? "Save" : "Edit"}
                </DsmButton>
                <div className={styles["data"]}>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.collection-date")}</b>
                    <span>{dayjs(sampleRequest.collectionDate).format(t("general.date-format"))}</span>
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.taken-by")}</b>
                    <span>{sampleRequest.takenBy || "-"}</span>
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.phase")}</b>
                    <span>
                      {sampleRequest.phaseName || "-"}
                      {sampleRequest.specificPhase && SpecificPhase[sampleRequest.specificPhase as keyof typeof SpecificPhase]
                        ? "- " + t(`general.specific-phase.${sampleRequest.specificPhase.toLocaleLowerCase()}`)
                        : sampleRequest.specificPhase}
                    </span>
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.farm")}</b>
                    <span>{sampleRequest.location?.name}</span>
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.barn")}</b>
                    <span>{sampleRequest.barn || "-"}</span>
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.problem-area")}</b>
                    <span>
                      {sampleRequest.problemArea && ProblemArea[sampleRequest.problemArea as keyof typeof ProblemArea]
                        ? t(`general.problem-area.${sampleRequest.problemArea.toLocaleLowerCase()}`)
                        : sampleRequest.problemArea || "-"}
                    </span>
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.specific-problem-area")}</b>
                    <span>
                      {sampleRequest.specificProblemArea &&
                      SpecificProblemArea[sampleRequest.specificProblemArea as keyof typeof SpecificProblemArea]
                        ? t(`general.specific-problem-area.${sampleRequest.specificProblemArea.toLocaleLowerCase()}`)
                        : sampleRequest.specificProblemArea || "-"}
                    </span>
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.genetic-supplier")}</b>
                    {isEdit && geneticSupplierOptions.length > 0 ? (
                      <div className={styles["data__cell--edit-vertical"]}>
                        <Select
                          fieldName="geneticSupplier"
                          control={control}
                          options={geneticSupplierOptions}
                          fieldError={errors.geneticSupplier}
                          forceRequired={true} //Cannot use isRequired because it is conditional
                        ></Select>
                        {geneticSupplier === "Other" ? (
                          <Input
                            control={control}
                            fieldName="geneticSupplierOther"
                            label={t("sample-request.genetic-supplier-other")}
                            fieldError={errors.geneticSupplierOther}
                            forceRequired={true} //Cannot use isRequired because it is conditional
                          ></Input>
                        ) : null}
                      </div>
                    ) : (
                      <span>{sampleRequest.samples?.[0]?.geneticSupplier || "-"}</span>
                    )}
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.genetic-lineage")}</b>
                    {isEdit && geneticLineageOptions.length > 0 ? (
                      <div className={styles["data__cell--edit-vertical"]}>
                        <Select
                          fieldName="geneticLineage"
                          control={control}
                          options={geneticLineageOptions}
                          fieldError={errors.geneticLineage}
                          forceRequired={true} //Cannot use isRequired because it is conditional
                        ></Select>
                        {geneticLineage === "Other" ? (
                          <Input
                            control={control}
                            fieldName="geneticLineageOther"
                            label={t("sample-request.genetic-lineage-other")}
                            fieldError={errors.geneticLineage}
                            forceRequired={true} //Cannot use isRequired because it is conditional
                          ></Input>
                        ) : null}
                      </div>
                    ) : (
                      <span>{sampleRequest.samples?.[0]?.geneticLineage || "-"}</span>
                    )}
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.housing")}</b>
                    <span>{sampleRequest.housingSystem || "-"}</span>
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.production")}</b>
                    <span>
                      {sampleRequest.productionSystem &&
                      ProductionSystemSwine[sampleRequest.productionSystem as keyof typeof ProductionSystemSwine]
                        ? t(`general.production-system-swine.${sampleRequest.productionSystem.toLocaleLowerCase()}`)
                        : sampleRequest.productionSystem || "-"}
                    </span>
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.sex")}</b>
                    <span>{sampleRequest.samples?.[0]?.sex || "-"}</span>
                  </div>
                  <div className={`${styles.data__cell} ${styles["data__cell--remark"]}`}>
                    <b>{t("sample-request.remarks")}</b>
                    <span>{sampleRequest.remarks || "-"}</span>
                  </div>
                </div>
                <h4 className={styles["data__sub-header"]}>{t("sample-request-read.dietary-info")}</h4>
                <div className={styles["data"]}>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.d3-level")}</b>
                    {isEdit ? (
                      <NumberInput
                        control={control}
                        fieldName="d3Level"
                        fieldError={errors.d3Level}
                        unit={t("sample-request.d3-level-unit")}
                      ></NumberInput>
                    ) : (
                      <span>
                        {sampleRequest.d3Level || "-"} {sampleRequest.d3Level ? t("sample-request.d3-level-unit") : ""}
                      </span>
                    )}
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.oh-level")}</b>
                    {isEdit ? (
                      <NumberInput
                        control={control}
                        fieldName="ohLevel"
                        fieldError={errors.ohLevel}
                        unit={t("sample-request.oh-level-unit")}
                      ></NumberInput>
                    ) : (
                      <span>
                        {sampleRequest.samples?.[0]?.dosage || "-"}{" "}
                        {sampleRequest.samples?.[0]?.dosage ? t("sample-request.oh-level-unit") : ""}
                      </span>
                    )}
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.oh-level-iu-kg")}</b>
                    {isEdit ? (
                      <DsmInput
                        unit={t("sample-request.oh-level-unit-iu-kg")}
                        value={calculateOhLevelIUKG(ohLevel)}
                        disabled={true}
                      ></DsmInput>
                    ) : (
                      <span>{ohLevel ? `${calculateOhLevelIUKG(ohLevel)} ${t("sample-request.oh-level-unit-iu-kg")}` : "-"}</span>
                    )}
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.total-vitamin-d3")}</b>
                    {isEdit ? (
                      <DsmInput
                        unit={t("sample-request.total-vitamin-d3-unit")}
                        value={calculateTotalVitamineD3(ohLevel, d3Level)}
                        disabled={true}
                      ></DsmInput>
                    ) : (
                      <span>
                        {ohLevel || d3Level
                          ? `${calculateTotalVitamineD3(ohLevel, d3Level)} ${t("sample-request.total-vitamin-d3-unit")}`
                          : "-"}
                      </span>
                    )}
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.ca-level")}</b>
                    {isEdit ? (
                      <NumberInput
                        control={control}
                        fieldName="caLevel"
                        fieldError={errors.caLevel}
                        schema={schema}
                        unit={t("sample-request.ca-level-unit")}
                      ></NumberInput>
                    ) : (
                      <span>
                        {sampleRequest.samples?.[0]?.feedComposition?.caLevel || "-"}{" "}
                        {sampleRequest.samples?.[0]?.feedComposition?.caLevel ? t("sample-request.ca-level-unit") : ""}
                      </span>
                    )}
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.p-total")}</b>
                    {isEdit ? (
                      <NumberInput
                        control={control}
                        fieldName="pTotal"
                        fieldError={errors.pTotal}
                        schema={schema}
                        unit={t("sample-request.p-total-unit")}
                      ></NumberInput>
                    ) : (
                      <span>
                        {sampleRequest.samples?.[0]?.feedComposition?.pLevel || "-"}{" "}
                        {sampleRequest.samples?.[0]?.feedComposition?.pLevel ? t("sample-request.p-total-unit") : ""}
                      </span>
                    )}
                  </div>
                  <div className={styles.data__cell}>
                    <b>{t("sample-request.phytase")}</b>
                    {isEdit ? (
                      <div className={styles["data__cell--edit-horizontal"]}>
                        <NumberInput control={control} fieldName="phytase" fieldError={errors.phytase} schema={schema}></NumberInput>
                        <Select
                          fieldName="phytaseUnit"
                          control={control}
                          options={phytaseUnitOptions}
                          fieldError={errors.phytaseUnit}
                          schema={schema}
                        ></Select>
                      </div>
                    ) : (
                      <span>
                        {sampleRequest.samples?.[0]?.feedComposition?.phytase
                          ? sampleRequest.samples?.[0]?.feedComposition?.phytase.toString() +
                            sampleRequest.samples?.[0]?.feedComposition?.phytaseUnit
                          : "-"}
                      </span>
                    )}
                  </div>
                </div>
                <div className={styles["data-container__hide"]} onClick={() => setShowFullData(false)}>
                  <DsmIcon name="arrows/chevron-up"></DsmIcon>
                  {t("general.show-less")}
                </div>
              </div>
            </div>
            <div className={styles["data-container__open"]} onClick={() => setShowFullData(true)}>
              <DsmIcon name="arrows/chevron-down"></DsmIcon>
              {t("general.show-more")}
            </div>
            <h3 className={styles["sub-header"]}>{t("sample-request-read.results")}</h3>
            <div className="panel">
              <div className={styles["main-results"]}>
                <div className="value-card">
                  <h5>{t("sample-request-read.result-sampled.title")}</h5>
                  <div className="value-card__value">
                    <span>{sampleRequest.samples?.length}</span>
                    <span>({sampleRequest.samples?.[0]?.sex})</span>
                  </div>
                </div>
                <div className="value-card">
                  <h5>{t("sample-request-read.result-average.title")}</h5>
                  <div className="value-card__value">
                    <span>{sampleRequest.average}</span>
                    <span>{t("sample-request-read.result-average.value")}</span>
                  </div>
                </div>
                <div className="value-card">
                  <h5>{t("sample-request-read.result-risk.title")}</h5>
                  <div className="value-card__value">
                    <span>{shouldShowLevels ? breakdownValues[0] + breakdownValues[1] : "-"}</span>
                    {shouldShowLevels ? <span>{t("sample-request-read.result-risk.value")}</span> : null}
                  </div>
                </div>
              </div>
              {isResultsPending() ? (
                <DsmAlert closeable={false} header={t("sample-request-read.results-pending-alert.title")}>
                  {t("sample-request-read.results-pending-alert.description")}
                </DsmAlert>
              ) : (
                <>
                  <div className={styles["results-summary"]}>
                    <h5>{t("sample-request-read.result-summary.result-title")}</h5>
                    {shouldShowLevels ? (
                      <ul className={styles["results-summary__level"]}>
                        {["deficient", "insufficient", "adequate", "optimum"].map((level, i) => (
                          <li>
                            {t(`general.test-reason-result-${level}.${problem}`, {
                              percentage: breakdownValues[i],
                              phase: getPhaseName(),
                              level: getPhaseLevel(level)
                            })}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className={styles["results-summary__level"]}>
                        {t("general.test-reason-result-description.average", { average: sampleRequest.average, phase: getPhaseName() })}
                      </div>
                    )}
                    <h5>{t("sample-request-read.result-summary.next-title")}</h5>
                    {shouldShowLevels ? (
                      <span dangerouslySetInnerHTML={{ __html: t("sample-request-read.hyd-alert.description") }}></span>
                    ) : (
                      <ul>
                        <li>{t("sample-request-read.hyd-alert.no-levels", { phase: getPhaseName() })}</li>
                        <li dangerouslySetInnerHTML={{ __html: t("sample-request-read.hyd-alert.description") }}></li>
                      </ul>
                    )}
                  </div>
                  <div className={styles["sub-header"]}>
                    <h3>{t("sample-request-read.result-specification.title")}</h3>
                    <DsmButtonGroup>
                      <DsmButtonGroupItem
                        onClick={() => setResultViewType(ResultViewType.Graph)}
                        active={resultViewType === ResultViewType.Graph}
                      >
                        {t("sample-request-read.result-specification.button-group.graph")}
                      </DsmButtonGroupItem>
                      {shouldShowLevels ? (
                        <DsmButtonGroupItem
                          onClick={() => setResultViewType(ResultViewType.Average)}
                          active={resultViewType === ResultViewType.Average}
                        >
                          {t("sample-request-read.result-specification.button-group.average")}
                        </DsmButtonGroupItem>
                      ) : null}
                      <DsmButtonGroupItem
                        onClick={() => setResultViewType(ResultViewType.Table)}
                        active={resultViewType === ResultViewType.Table}
                      >
                        {t("sample-request-read.result-specification.button-group.table")}
                      </DsmButtonGroupItem>
                    </DsmButtonGroup>
                  </div>
                  <div className={`${styles["individual-results"]} ${resultViewType === ResultViewType.Graph ? "" : styles.hide}`}>
                    <Plot
                      className={`scatter ${styles.scatter}`}
                      data={[
                        {
                          y: resultValues,
                          x: sampleRequest?.samples?.map((_, i) => `${i + 1}`) || [],
                          text:
                            sampleRequest?.samples?.map(
                              (_) =>
                                `${t("sample-request-read.individual-results-table.result")}: <b>${_.results?.[0]?.result || 0}${
                                  hasRemark(_.results?.[0]) ? "*" : ""
                                } ng/mL</b>
                              ${hasRemark(_.results?.[0]) ? `<br>${t("sample-request.remark")}: <b>${getRemark(_.results?.[0])}</b>` : ""}
                              ${_.animalId ? `<br>${t("sample-request.animal")}: <b>${_.animalId}</b>` : ""}
                              ${_.parity ? `<br>${t("sample-request.parity")}: <b>${_.parity}</b>` : ""}
                              ${
                                sampleRequest.specificProblemArea
                                  ? `<br>${t(`general.test-reason-problem.${sampleRequest?.problem?.toLocaleLowerCase()}`)}: <b>${
                                      _.problemAnswer === true
                                        ? t("general.yes-no.yes")
                                        : _.problemAnswer === false
                                        ? t("general.yes-no.no")
                                        : t("general.yes-no.unknown")
                                    }</b>`
                                  : ""
                              }`
                            ) || [],
                          mode: "markers",
                          type: "scatter",
                          marker: {
                            size: 12,
                            color: resultValues.map((_) => getMarkerColorForValue(_))
                          },
                          hovertemplate: `%{text}<extra></extra>`
                        }
                      ]}
                      layout={{
                        width: isPrinting ? 960 : undefined,
                        paper_bgcolor: "transparent",
                        plot_bgcolor: "transparent",
                        height: 500,
                        hoverlabel: {
                          align: "left",
                          bgcolor: "#fff",
                          bordercolor: "#fff",
                          font: { color: "#000", family: '"DM Sans", sans-serif' }
                        },
                        margin: { l: 48, r: 0, t: 8, b: 8, pad: 8 },
                        font: {
                          family: '"DM Sans", sans-serif'
                        },
                        xaxis: { showgrid: false, showticklabels: false },
                        yaxis: {
                          title: t("sample-request-read.individual-results-chart.yaxis"),
                          range: [0, Math.max(...resultValues.concat([sampleRequest?.phase?.optimum || 0]))],
                          tickvals: [
                            0,
                            sampleRequest.phase?.deficient,
                            sampleRequest.phase?.insufficient,
                            sampleRequest.phase?.adequate,
                            Math.max(...resultValues.concat([sampleRequest?.phase?.optimum || 0]))
                          ],
                          zerolinecolor: "transparent",
                          gridcolor: "transparent"
                        },
                        shapes: [
                          {
                            type: "line",
                            layer: "below",
                            y0: sampleRequest.average || 0,
                            y1: sampleRequest.average || 0,
                            x0: 0,
                            x1: sampleRequest.samples ? sampleRequest.samples.length + 1 : 0,
                            opacity: 1,
                            line: {
                              color: "#808080",
                              width: 1,
                              dash: "dot"
                            }
                          }
                        ],
                        dragmode: false
                      }}
                      config={{ staticPlot: false, responsive: true, displayModeBar: false, doubleClick: false }}
                      useResizeHandler={true}
                      style={{ width: "100%", height: "500px" }}
                    />
                    {shouldShowLevels ? (
                      <div className={styles.legend}>
                        <div className={`${styles["legend__average"]}`}>{`${t("sample-request-read.individual-results-chart.average")}: ${
                          sampleRequest.average
                        }`}</div>
                        {sampleRequest?.phase?.optimum ? (
                          <div className={`${styles["legend__item"]} ${styles["legend__item--optimum"]}`}>
                            {t("general.result-breakdown.optimum")}
                          </div>
                        ) : null}
                        {sampleRequest?.phase?.adequate ? (
                          <div className={`${styles["legend__item"]} ${styles["legend__item--adequate"]}`}>
                            {t("general.result-breakdown.adequate")}
                          </div>
                        ) : null}
                        {sampleRequest?.phase?.insufficient ? (
                          <div className={`${styles["legend__item"]} ${styles["legend__item--insufficient"]}`}>
                            {t("general.result-breakdown.insufficient")}
                          </div>
                        ) : null}
                        {sampleRequest?.phase?.deficient ? (
                          <div className={`${styles["legend__item"]} ${styles["legend__item--deficient"]}`}>
                            {t("general.result-breakdown.deficient")}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {shouldShowLevels && resultList ? (
                    <div className={`${resultViewType === ResultViewType.Average ? "" : styles.hide}`}>
                      <ResultList data={resultList}></ResultList>
                    </div>
                  ) : null}
                  <DsmTable
                    className={`${styles.table} ${resultViewType === ResultViewType.Table ? "" : styles.hide}`}
                    data={samplesTable}
                  ></DsmTable>
                </>
              )}
            </div>
          </>
        ) : null}
        <div className={styles["print-footer"]}>
          <span>www.dsm-firmenich.com/anh</span>
          <span>©{new Date().getFullYear()} dsm-firmenich</span>
          <img src={`${DsmCDNUrl}/images/logo.svg`} alt="logo"></img>
        </div>
      </DsmGrid>
      {isLoading ? <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator> : null}
    </>
  );
}

export default SampleRequestRead;
