import { DsmEmptyState, DsmGrid, DsmLoadingIndicator, DsmPagination, DsmTable, DsmTabs } from "@dsm-dcs/design-system-react";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSampleResultCalculationsForLocation, getSampleResultsForLocation } from "../../../services/sample.service";
import { DsmTableData, RowActionClickEvent } from "@dsm-dcs/design-system/dist/types/types/table";
import { routeTypes, routes } from "../../../routes";
import { DsmTableCustomEvent } from "@dsm-dcs/design-system";
import { getLocation } from "../../../services/location.service";
import { BasePhase, Location, Page, SampleResultCalculations, SampleResultTableInput } from "../../../models/API";
import PageHeader from "../../../components/pageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import ResultList from "../../../components/resultList/ResultList";
import styles from "./FarmSampleRequestList.module.scss";
import { useLayout } from "../../../contexts/layout.context";
import { getAverageText, handleResultListData, handleSamplesTableData } from "../../../helpers/sampleData.helper";
import { SampleListTableActions } from "../../../models/enums/actions.enum";
import { IResultList } from "../../../models/resultList.model";
import { AuthContext } from "../../../contexts/auth.context";
import { Role } from "../../../models/enums/role.enum";

function FarmSampleRequestList() {
  //Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { locationId } = useParams();
  const { customer, role } = useContext(AuthContext);

  //State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingForPhase, setIsLoadingForPhase] = useState<boolean>(false);
  const [location, setLocation] = useState<Location | null>(null);
  const [availablePhases, setAvailablePhases] = useState<BasePhase[]>([]);
  const [currentPhaseId, setCurrentPhaseId] = useState<string | null>(null);
  const [currentSamplesTablePage, setCurrentSamplesTablePage] = useState<number>(1);
  const [sampleCalculations, setSampleCalculations] = useState<SampleResultCalculations | null>(null);
  const [resultList, setResultList] = useState<IResultList | null>(null);
  const [samplesTable, setSamplesTable] = useState<DsmTableData>();
  const [samplesTablePages, setSamplesTablePages] = useState<Page[]>([]);
  const [shouldShowLevels, setShouldShowLevels] = useState<boolean>(true);

  //Effect
  useEffect(() => {
    setPageTitle("");
    handleCrumbs();
    initData();
  }, []);

  useEffect(() => {
    if (!location) {
      return;
    }
    setPageTitle(location?.name || "");
  }, [location]);

  useEffect(() => {
    handleCrumbs();
  }, [customer, location, isLoading]);

  //Methods
  const initData = async () => {
    const loadLocation = getLocation(locationId || "", setToast);
    const loadDataForPhase = getDataForPhase(null, true);

    await Promise.all([loadLocation, loadDataForPhase]).then(async (data) => {
      setLocation(data[0]);
    });
    setIsLoading(false);
  };

  const handleCrumbs = () => {
    const startCrumbs = [];
    if (role === Role.Manager || role === Role.Admin) {
      startCrumbs.push(
        { title: t("customers.page.title"), url: routes.customers, type: routeTypes.customers },
        { title: customer?.name ?? "...", url: routes.customers, type: routeTypes.customerSelected }
      );
    } else {
      startCrumbs.push({ title: t("farms.page.title"), url: routes.farms, type: routeTypes.farms });
    }

    if (isLoading) {
      setCrumbs([...startCrumbs, { title: "...", type: routeTypes.loading }]);
    } else {
      setCrumbs([...startCrumbs, { title: location?.name || "", type: routeTypes.farmSamples }]);
    }
  };

  const getDataForPhase = async (phaseId: string | null, initial: boolean) => {
    const loadSampleCalculationsForPhase = getSampleResultCalculationsForLocation(locationId || "", phaseId, setToast);

    const sampleResultTableInput: SampleResultTableInput = {
      itemsPerPage: 10,
      phaseId,
      excludePendingResults: false
    };
    const loadSamplesForPhase = getSampleResultsForLocation(locationId || "", sampleResultTableInput, setToast);

    await Promise.all([loadSampleCalculationsForPhase, loadSamplesForPhase]).then(async (data) => {
      if (initial) {
        //Only set available phases on first load
        setAvailablePhases(data[0]?.availablePhases || []);
      }
      setResultList(data[0] ? handleResultListData(data[0]) : null);
      setSampleCalculations(data[0]);
      setSamplesTable(handleSamplesTableData(data[1]?.rows || [], data[0] || null));
      setSamplesTablePages(data[1]?.pages || []);
      setShouldShowLevels(
        !data[0]?.selectedPhase ||
          !!data[0]?.selectedPhase?.adequate ||
          !!data[0]?.selectedPhase?.insufficient ||
          !!data[0]?.selectedPhase?.deficient
      );
    });
  };

  const handlePhaseChange = async (phaseId: string | null) => {
    setIsLoadingForPhase(true);
    setCurrentSamplesTablePage(1);
    setCurrentPhaseId(phaseId);
    await getDataForPhase(phaseId, false);
    setIsLoadingForPhase(false);
  };

  const handleResultListPageChange = async (page: number) => {
    setCurrentSamplesTablePage(page);
    const pageData = samplesTablePages.find((_) => _.page === page - 1);

    const sampleResultTableInput: SampleResultTableInput = {
      itemsPerPage: 10,
      phaseId: currentPhaseId,
      pageToken: pageData?.token || null,
      excludePendingResults: false
    };
    const samplesData = await getSampleResultsForLocation(locationId || "", sampleResultTableInput, setToast);
    setSamplesTable(handleSamplesTableData(samplesData?.rows || [], sampleCalculations));
  };

  const handleTableRowClick = (e: DsmTableCustomEvent<string>) => {
    navigate(routes.farmSample.replace(":sampleID", e.detail));
  };

  const handleTableAction = (e: DsmTableCustomEvent<RowActionClickEvent>) => {
    if (e.detail.action === SampleListTableActions.Results) {
      navigate(routes.farmSample.replace(":sampleID", e.detail.id));
    }
  };

  return (
    <>
      <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
        {!isLoading ? (
          <>
            <PageHeader header={location?.name || ""} description={t("farm-sample-request-list.page.description")}></PageHeader>
            <div className="sub-header">
              <h3>{t("farm-sample-request-list.results-header")}</h3>
            </div>
            {availablePhases.length > 0 ? (
              <DsmTabs className={styles["phase-filter"]}>
                <a slot="navigation" className={`${currentPhaseId === null ? "active" : ""}`} onClick={() => handlePhaseChange(null)}>
                  {t("general.all")}
                </a>
                {availablePhases?.map((phase) => (
                  <a
                    slot="navigation"
                    className={`${currentPhaseId === phase.id ? "active" : ""}`}
                    onClick={() => handlePhaseChange(phase.id || null)}
                    key={phase.id}
                  >
                    {phase.name}
                  </a>
                ))}
              </DsmTabs>
            ) : null}
            {!isLoadingForPhase ? (
              <>
                {sampleCalculations?.totalAverage && sampleCalculations.totalAverage > 0 ? (
                  <>
                    <h3>{t("farm-sample-request-list.result-overview.title")}</h3>
                    <div className="panel">
                      <div className={styles["result-cards"]}>
                        <div className="value-card">
                          <h5>{t("farm-sample-request-list.result-average.title")}</h5>
                          <div className="value-card__value">
                            <span>{sampleCalculations.totalAverage || "0"}</span>
                            <span>{t("farm-sample-request-list.result-average.value")}</span>
                          </div>
                        </div>
                        {sampleCalculations.selectedPhase ? (
                          <div className="value-card">
                            <h5>{t("farm-sample-request-list.result-type.title")}</h5>
                            <div className="value-card__value">
                              <span>{getAverageText(sampleCalculations?.selectedPhase, sampleCalculations.totalAverage)}</span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {shouldShowLevels && resultList ? <ResultList data={resultList}></ResultList> : null}
                    </div>
                  </>
                ) : null}
                <div className={`card card--table ${styles["samples-table"]}`}>
                  {samplesTable?.data.length ? (
                    <>
                      <DsmTable data={samplesTable} onDsmRowClick={handleTableRowClick} onDsmRowActionClick={handleTableAction}></DsmTable>
                      {samplesTablePages.length > 0 ? (
                        <DsmPagination
                          currentPage={currentSamplesTablePage}
                          pageCount={samplesTablePages.length}
                          onDsmChangePage={(e) => handleResultListPageChange(e.detail)}
                        ></DsmPagination>
                      ) : null}
                    </>
                  ) : (
                    <DsmEmptyState icon="charts/pie-chart-01" header={t("farm-sample-request-list.samples-table.empty")}></DsmEmptyState>
                  )}
                </div>
              </>
            ) : (
              <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator>
            )}
          </>
        ) : null}
      </DsmGrid>
      {isLoading ? <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator> : null}
    </>
  );
}

export default FarmSampleRequestList;
