import { DsmRadioButton, DsmRadioButtonGroup } from "@dsm-dcs/design-system-react";
import { isRequired } from "../../../helpers/validation.helper";
import { Control, Controller, FieldError } from "react-hook-form";
import { AnyObject, ObjectSchema } from "yup";
import { DsmSelectOption } from "@dsm-dcs/design-system";
import styles from "./RadioButtonGroup.module.scss";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  fieldName: string;
  label?: string;
  tooltip?: string;
  options: DsmSelectOption[];
  schema?: ObjectSchema<AnyObject>;
  fieldError?: FieldError;
  forceRequired?: boolean;
  disabled?: boolean;
  noOptionsText?: string;
};

function RadioButtonGroup({
  control,
  fieldName,
  label,
  tooltip,
  options,
  schema,
  fieldError,
  forceRequired,
  disabled,
  noOptionsText
}: Props) {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <DsmRadioButtonGroup
          data-testid={`radio-button-group-${fieldName}`}
          data-value={value} //TODO: Find better method to get value in tests
          direction="row"
          label={label}
          tooltip={tooltip}
          errorText={fieldError?.message}
          required={forceRequired || isRequired(schema, fieldName)}
          disabled={disabled}
        >
          {options.length === 0 && noOptionsText ? <div className={styles["no-option-text"]}>{noOptionsText}</div> : null}
          {options.map((option) => (
            <DsmRadioButton
              onDsmChange={(e) => {
                onChange(e.detail);
                onBlur();
              }}
              value={option.value}
              label={option.text}
              key={option.value}
              checked={option.value === value}
            ></DsmRadioButton>
          ))}
        </DsmRadioButtonGroup>
      )}
    />
  );
}

export default RadioButtonGroup;
