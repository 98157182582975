/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const addKitRequest = /* GraphQL */ `mutation AddKitRequest($input: KitRequestInput!) {
  addKitRequest(input: $input) {
    kitRequest {
      address {
        city
        country {
          abbreviation
          id
          name
        }
        number
        postalCode
        state
        street
      }
      contactName
      id
      location {
        address {
          city
          number
          postalCode
          state
          street
        }
        avgNrOfAnimals
        contact {
          id
          name
          relation
        }
        groups
        id
        name
        phone
      }
      orders {
        amount
        createdDate
        id
        kit {
          currency
          id
          name
          priceEx
          vatPercentage
        }
        status
        trackAndTrace
        transportProvider
      }
      sentToLocation
    }
  }
}
` as GeneratedMutation<
  APITypes.AddKitRequestMutationVariables,
  APITypes.AddKitRequestMutation
>;
export const addLocationForCustomer = /* GraphQL */ `mutation AddLocationForCustomer($input: LocationInput!) {
  addLocationForCustomer(input: $input) {
    location {
      address {
        city
        country {
          abbreviation
          id
          name
        }
        number
        postalCode
        state
        street
      }
      avgNrOfAnimals
      contact {
        id
        name
        relation
      }
      customer {
        currency
        currentBudget
        dsmUnit {
          id
          name
        }
        id
        initialBudget
        locations {
          avgNrOfAnimals
          groups
          id
          name
          phone
        }
        name
      }
      groups
      id
      name
      phone
    }
  }
}
` as GeneratedMutation<
  APITypes.AddLocationForCustomerMutationVariables,
  APITypes.AddLocationForCustomerMutation
>;
export const addProductCompetitor = /* GraphQL */ `mutation AddProductCompetitor($input: ProductCompetitorInput!) {
  addProductCompetitor(input: $input) {
    competitor {
      customer {
        currency
        currentBudget
        dsmUnit {
          id
          name
        }
        id
        initialBudget
        locations {
          avgNrOfAnimals
          groups
          id
          name
          phone
        }
        name
      }
      id
      name
      productType {
        id
        name
      }
      sampleType {
        id
        name
        productType {
          id
          name
        }
      }
    }
  }
}
` as GeneratedMutation<
  APITypes.AddProductCompetitorMutationVariables,
  APITypes.AddProductCompetitorMutation
>;
export const addSampleRequest = /* GraphQL */ `mutation AddSampleRequest($input: SampleRequestInput!) {
  addSampleRequest(input: $input) {
    sampleRequest {
      average
      barn
      collectionDate
      comment
      d3Level
      housingSystem
      id
      location {
        address {
          city
          number
          postalCode
          state
          street
        }
        avgNrOfAnimals
        contact {
          id
          name
          relation
        }
        customer {
          currency
          currentBudget
          id
          initialBudget
          name
        }
        groups
        id
        name
        phone
      }
      name
      phase {
        adequate
        deficient
        geneticLineage {
          id
          name
        }
        geneticSupplier {
          id
          name
        }
        id
        insufficient
        name
        optimum
        testReasonConfig
      }
      phaseName
      problem
      problemArea
      productionSystem
      remarks
      samples {
        age
        analytes {
          id
          name
        }
        animalId
        cardId
        collectionDate
        comment
        costCenter {
          id
          name
        }
        dosage
        feedComposition {
          caLevel
          pAvailable
          pLevel
          phytase
          phytaseUnit
        }
        geneticLineage
        geneticSupplier
        id
        parity
        phase {
          adequate
          deficient
          id
          insufficient
          name
          optimum
          testReasonConfig
        }
        problemAnswer
        productCategory {
          id
          name
        }
        productType {
          id
          name
        }
        results {
          analysis
          analysisUnit
          evaluation
          expected
          id
          line
          module
          outlier
          repetitions
          result
          unit
        }
        sex
        sowStage
        species {
          enabled
          group
          id
          name
        }
        timeline {
          comments
          date
          description
          id
          order
          status
        }
      }
      specificPhase
      specificProblemArea
      takenBy
      testReason
      testReasonProblem
    }
  }
}
` as GeneratedMutation<
  APITypes.AddSampleRequestMutationVariables,
  APITypes.AddSampleRequestMutation
>;
export const editSampleRequest = /* GraphQL */ `mutation EditSampleRequest($input: SampleRequestEditInput!) {
  editSampleRequest(input: $input) {
    sampleRequest {
      average
      barn
      collectionDate
      comment
      d3Level
      housingSystem
      id
      location {
        address {
          city
          number
          postalCode
          state
          street
        }
        avgNrOfAnimals
        contact {
          id
          name
          relation
        }
        customer {
          currency
          currentBudget
          id
          initialBudget
          name
        }
        groups
        id
        name
        phone
      }
      name
      phase {
        adequate
        deficient
        geneticLineage {
          id
          name
        }
        geneticSupplier {
          id
          name
        }
        id
        insufficient
        name
        optimum
        testReasonConfig
      }
      phaseName
      problem
      problemArea
      productionSystem
      remarks
      samples {
        age
        analytes {
          id
          name
        }
        animalId
        cardId
        collectionDate
        comment
        costCenter {
          id
          name
        }
        dosage
        feedComposition {
          caLevel
          pAvailable
          pLevel
          phytase
          phytaseUnit
        }
        geneticLineage
        geneticSupplier
        id
        parity
        phase {
          adequate
          deficient
          id
          insufficient
          name
          optimum
          testReasonConfig
        }
        problemAnswer
        productCategory {
          id
          name
        }
        productType {
          id
          name
        }
        results {
          analysis
          analysisUnit
          evaluation
          expected
          id
          line
          module
          outlier
          repetitions
          result
          unit
        }
        sex
        sowStage
        species {
          enabled
          group
          id
          name
        }
        timeline {
          comments
          date
          description
          id
          order
          status
        }
      }
      specificPhase
      specificProblemArea
      takenBy
      testReason
      testReasonProblem
    }
  }
}
` as GeneratedMutation<
  APITypes.EditSampleRequestMutationVariables,
  APITypes.EditSampleRequestMutation
>;
export const updateNotificationReadStatus = /* GraphQL */ `mutation UpdateNotificationReadStatus($input: NotificationInput!) {
  updateNotificationReadStatus(input: $input) {
    notification {
      createdAt
      customer {
        currency
        currentBudget
        dsmUnit {
          id
          name
        }
        id
        initialBudget
        locations {
          avgNrOfAnimals
          groups
          id
          name
          phone
        }
        name
      }
      description
      id
      messageType
      readBy
      referenceId
      source
      status
      transportProvider
    }
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationReadStatusMutationVariables,
  APITypes.UpdateNotificationReadStatusMutation
>;
