import { ContactType } from "../enums/contactType.enum";
import * as yup from "yup";
import i18next from "i18next";
import { SpeciesGroup } from "../API";

export interface AddFarmForm {
  customerId: string;
  name: string;
  species: SpeciesGroup;
  contactType: ContactType;
  contactPerson: string;
  phone?: string | null;
  street: string;
  number: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
}

export const getFarmFormSchema = () => {
  return yup
    .object({
      customerId: yup.string().required().label(i18next.t("farm.customer")),
      name: yup.string().required().label(i18next.t("farm.name")),
      species: yup.mixed<SpeciesGroup>().oneOf(Object.values(SpeciesGroup)).required().label(i18next.t("farm.species")),
      contactType: yup.mixed<ContactType>().oneOf(Object.values(ContactType)).required().label(i18next.t("farm.contact-type")),
      contactPerson: yup.string().required().label(i18next.t("farm.contact-person")),
      phone: yup.string().notRequired().label(i18next.t("farm.phone")),
      street: yup.string().required().label(i18next.t("general.address.street")),
      number: yup.string().required().label(i18next.t("general.address.number")),
      postalCode: yup.string().required().label(i18next.t("general.address.postal-code")),
      city: yup.string().required().label(i18next.t("general.address.city")),
      state: yup.string().required().label(i18next.t("general.address.state")),
      country: yup.string().required().label(i18next.t("general.address.country"))
    })
    .required();
};
