import { ReactNode } from "react";
import styles from "./PageHeader.module.scss";
import useBreakpoint, { Breakpoints } from "../../hooks/breakpoint.hook";

type Props = {
  header: string;
  description?: string;
  actionsSide?: ReactNode;
  children?: ReactNode;
  videoUrl?: string;
  videoPoster?: string;
  videoTitle?: string;
};

function PageHeader({ header, description, children, actionsSide, videoUrl, videoPoster, videoTitle }: Props) {
  const breakpoint = useBreakpoint();

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <h2 className={styles.header}>{header}</h2>
        {!!description || !!videoUrl || !!children ? (
          <div className={styles.content}>
            {description ? <span dangerouslySetInnerHTML={{ __html: description }}></span> : null}
            {videoUrl && (breakpoint === Breakpoints.xs || breakpoint === Breakpoints.sm) ? (
              <video src={videoUrl} title={videoTitle} controls poster={videoPoster} preload="none" controlsList="nodownload"></video>
            ) : null}
            {children ? <div className={`${styles.actions} ${styles["actions--description"]}`}>{children}</div> : null}
          </div>
        ) : null}
      </div>
      <div className={styles.side}>
        {videoUrl && breakpoint !== Breakpoints.xs && breakpoint !== Breakpoints.sm ? (
          <video src={videoUrl} title={videoTitle} controls poster={videoPoster} preload="none" controlsList="nodownload"></video>
        ) : null}
        {actionsSide ? <div className={`${styles.actions}`}>{actionsSide}</div> : null}
      </div>
    </div>
  );
}

export default PageHeader;
