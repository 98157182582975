import { DsmColors } from "@dsm-dcs/design-system";
import { KitOrderStatus } from "../models/API";

export const getVariantForStatus = (status: KitOrderStatus): DsmColors => {
  switch (status) {
    case KitOrderStatus.Error:
    case KitOrderStatus.Cancelled:
      return "error";
    case KitOrderStatus.Shipped:
      return "cyan";
    case KitOrderStatus.Received:
      return "success";
    default:
      return "primary";
  }
};
