import { useEffect } from "react";

function SSOLogin() {
  useEffect(() => {
    if (window.gigya) return;

    const script = document.createElement("script");
    script.src = "https://cdns.gigya.com/js/gigya.js?apiKey=" + process.env.REACT_APP_ADOBE_GIGYA_KEY;
    script.type = "text/javascript";
    script.onload = onLoad;
    document.body.appendChild(script);
  });

  const onLoad = () => {
    window.gigya?.accounts.addEventHandlers({
      onLogin: function () {
        window.gigya.fidm.saml.continueSSO();
      }
    });

    window.gigya?.accounts.showScreenSet({
      screenSet: "Brandcenter-RegistrationLogin",
      containerID: "container",
      sessionExpiration: "20000"
    });
  };

  return <div id="container"></div>;
}

export default SSOLogin;
