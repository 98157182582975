/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from "aws-amplify";

export const awsConfig: any = {
  aws_appsync_graphqlEndpoint: "https://lghoeyyrpvbptn3mxonjtmwtda.appsync-api.eu-west-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-4szglowvd5bcrpjbxcpvs7pgxu",
  Auth: {
    userPoolId: "eu-west-1_ynqFvyZgM",
    userPoolWebClientId: "7fsklqnhvihe44sav40ia1p7om",
    oauth: {
      domain: "myhyd-backend-test.auth.eu-west-1.amazoncognito.com",
      scope: ["email", "openid", "phone", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: "https://myhyd.test.productsupport.dsm.com",
      redirectSignOut: "https://myhyd.test.productsupport.dsm.com",
      responseType: "code"
    }
  },
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken()
      };
    }
  }
};
