import { DsmButton, DsmEmptyState, DsmGrid } from "@dsm-dcs/design-system-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routeTypes, routes } from "../../../routes";
import styles from "./Error.module.scss";
import { useEffect } from "react";
import { useLayout } from "../../../contexts/layout.context";

function Error() {
  //Hooks
  const { setPageTitle, setCrumbs } = useLayout();
  const navigate = useNavigate();
  const { t } = useTranslation();

  //Effects
  useEffect(() => {
    setPageTitle("");
    setCrumbs([{ title: "", type: routeTypes.error }]);
  }, []);

  return (
    <DsmGrid className={`main-container ${styles.container}`} fixed={true} container-only={true}>
      <DsmEmptyState header={t("error.not-found.header")} description={t("error.not-found.description")} icon="users/face-frown">
        <DsmButton
          slot="actions"
          onClick={() => {
            navigate(routes.home);
          }}
        >
          {t("error.not-found.home")}
        </DsmButton>
        <DsmButton
          slot="actions"
          variant="secondary"
          onClick={() => {
            window.history.back();
          }}
        >
          {t("error.not-found.back")}
        </DsmButton>
      </DsmEmptyState>
    </DsmGrid>
  );
}

export default Error;
