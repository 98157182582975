export enum FarmListTableActions {
  Results = "Results",
  Edit = "Edit",
  Delete = "Delete"
}

export enum SampleListTableActions {
  Results = "Results",
  Delete = "Delete"
}

export enum CustomersTableActions {
  CreateKitRequest = "CreateKitRequest",
  CreateSampleRequest = "CreateSampleRequest",
  CreateFarm = "CreateFarm"
}
