/* eslint-disable @typescript-eslint/no-explicit-any */
import packageJson from "../../package.json";
import { IBreadcrumb } from "../models/breadcrumb.model";
import { Role } from "../models/enums/role.enum";
import { routeTypes } from "../routes";

export const triggerPageAnalytics = (crumbs: IBreadcrumb[]) => {
  //Don't send analytics if the page is loading
  if (crumbs.some((crumb) => crumb.type === routeTypes.loading)) return;

  const currentPageInfo = (window as any).digitalData?.page?.pageInfo;

  window.digitalData.page.pageInfo = {
    language: "en", //Only one language is supported
    pageId: crumbs.length > 0 ? crumbs[crumbs.length - 1].type : "",
    pageType: "Default layout", //There is only one layout
    version: packageJson.version,
    referringURL: currentPageInfo?.currentURL || document.referrer,
    breadcrumb: crumbs?.map((crumb) => crumb.type) || [],
    currentURL: window.location.href,
    siteSection: crumbs.length > 0 ? crumbs[0].type : "",
    errorPage: crumbs.some((crumb) => crumb.type === routeTypes.error)
  };
  window._satellite?.track("pageViewed");

  if (process.env.NODE_ENV === "development") {
    console.info("Track page analytics", (window as any).digitalData);
  }
};

export const setUserAnalytics = (userID: string, role: Role) => {
  window.digitalData.page.user.profileInfo = {
    userRole: role ? role : "",
    loginStatus: userID ? "LoggedIn" : "LoggedOut",
    encryptedLoginID: userID
  };

  if (process.env.NODE_ENV === "development") {
    console.info("User analytics set", (window as any).digitalData);
  }
};

export const triggerLoginAnalytics = () => {
  window._satellite?.track("loginSuccessful");

  if (process.env.NODE_ENV === "development") {
    console.info("Track login analytics", (window as any).digitalData);
  }
};
