import { useEffect, useState } from "react";
import { IResultList } from "../../models/resultList.model";
import styles from "./ResultList.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  data: IResultList;
};

function ResultList({ data }: Props) {
  const { t } = useTranslation();
  const [results, setResults] = useState<IResultList>();

  useEffect(() => {
    setResults(data);
  }, [data]);

  return (
    <div className={styles["result-list"]}>
      <div className={styles.bar}>
        {results?.data.map((row, index) => (
          <div className={`${styles["bar__item"]} ${styles[`bar__item--${row.type}`]}`} style={{ flexBasis: `${row.value}%` }} key={index}>
            <div className={styles["bar__level"]}>{t(`general.result-breakdown.${row.type}`)}</div>
            {row.range ? <div className={styles["bar__range"]}>{row.range}</div> : null}
            <div className={styles["bar__value"]}>
              <span>{row.value}</span>
              <span>%</span>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.list}>
        {results?.data.map((row, index) => (
          <div className={styles["list__item"]} key={index}>
            <div className={`${styles["list__level"]} ${styles[`list__level--${row.type}`]}`}>
              <div>
                <b>{row.header}</b>
                <div dangerouslySetInnerHTML={{ __html: row.description }}></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ResultList;
