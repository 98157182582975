import { Auth } from "aws-amplify";
import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
import { BaseCustomer, Customer } from "../models/API";
import { getCustomerForUser, getCustomer } from "../services/user.service";
import { IToast } from "../models/toast.model";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { setUserAnalytics, triggerLoginAnalytics } from "../helpers/analytics.helper";
import { Role } from "../models/enums/role.enum";

type Props = {
  children?: ReactNode;
  setToast: Dispatch<SetStateAction<IToast | null>>;
};

type AuthContextProps = {
  isLoading: boolean;
  isAuthorized: boolean;
  role: Role | null;
  customer: BaseCustomer | Customer | null;
  handleCustomer: (customer: BaseCustomer | Customer | null) => void;
  handleCustomerById: (customerId: string) => Promise<boolean>;
};

const AuthProvider = ({ children, setToast }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [role, setRole] = useState<Role | null>(null);
  const [customer, setCustomer] = useState<BaseCustomer | Customer | null>(null);

  //Effects
  useEffect(() => {
    initData();
  }, []);

  const handleCustomer = (customer: BaseCustomer | Customer | null) => {
    setCustomer(customer);
    sessionStorage.setItem("customerId", customer?.id || "");
  };

  const handleCustomerById = async (customerId: string): Promise<boolean> => {
    const customer = await getCustomer(customerId, setToast);
    handleCustomer(customer);
    return !!customer;
  };

  //Methods
  const initData = async () => {
    try {
      //Save url params before aws-amplify removes code params
      const urlParams = new URLSearchParams(window.location.search);

      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const userAttributes = await Auth.userAttributes(authenticatedUser);
      const session = await Auth.currentSession();

      let userRole: Role = Role.Customer;

      if (session.getIdToken().payload["custom:isManager"] && session.getIdToken().payload["custom:isManager"] === "true") {
       userRole = Role.Manager;
      }

      if (userAttributes.find((attribute) => attribute.Name === "custom:isAdmin")?.Value === "true") {
        userRole = Role.Admin;
      }

      const authenticated = authenticatedUser !== undefined;

      setUserAnalytics(authenticatedUser?.attributes?.sub || "", userRole);
      if (authenticated) {
        if (urlParams.get("code")) {
          triggerLoginAnalytics();
        }

        if (userRole === Role.Customer) {
          setCustomer(await getCustomerForUser(setToast));
        } else if (role === Role.Manager || role === Role.Admin) {
          const customerId = sessionStorage.getItem("customerId");
          if (customerId) {
            handleCustomerById(customerId);
          }
        }
      }
      setRole(userRole);
      setIsAuthorized(authenticated);
    } catch {
      setRole(Role.Customer);
      setIsAuthorized(false);
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Cognito
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{ isLoading, isAuthorized, customer, role, handleCustomer, handleCustomerById }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthContext = createContext<AuthContextProps>({
  isLoading: false,
  isAuthorized: false,
  customer: null,
  role: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleCustomer: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleCustomerById: () => Promise.resolve(false)
});
export default AuthProvider;
