export enum YesNo {
  Yes = "Yes",
  No = "No"
}

export enum YesNoUnknown {
  Yes = "Yes",
  No = "No",
  Unknown = "unknown"
}
