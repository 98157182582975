import { useContext, useEffect, useState } from "react";
import { useLayout } from "../../../contexts/layout.context";
import { useTranslation } from "react-i18next";
import { routes, routeTypes } from "../../../routes";
import { DsmSelectOption, DsmTableCustomEvent, DsmTableData } from "@dsm-dcs/design-system";
import { getSampleResultsForCustomer } from "../../../services/sample.service";
import { AuthContext } from "../../../contexts/auth.context";
import { Role } from "../../../models/enums/role.enum";
import { ComparisonType, FloatFilter, Page, SampleResultTableItem, SortDirection, SpeciesGroup } from "../../../models/API";
import dayjs from "dayjs";
import { DsmEmptyState, DsmGrid, DsmLoadingIndicator, DsmPagination, DsmTable } from "@dsm-dcs/design-system-react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/pageHeader/PageHeader";
import { useForm } from "react-hook-form";
import { getFilterSampleRequestFormSchema, ResultsFilterOption } from "../../../models/forms/filter-sample-request-form.model";
import { yupResolver } from "@hookform/resolvers/yup";
import SearchInput from "../../../components/filter/searchInput/SearchInput";
import { getPhaseForSelect, getSpecies } from "../../../services/metaData.service";
import Select from "../../../components/form/select/Select";
import FilterContainer from "../../../components/filter/filterContainer/filterContainer";
import RadioButtonGroup from "../../../components/form/radioButtonGroup/RadioButtonGroup";
import NumberInput from "../../../components/form/numberInput/NumberInput";
import DateInput from "../../../components/form/dateInput/DateInput";

function SampleRequestList() {
  //Hooks
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customer, role } = useContext(AuthContext);
  const [schema] = useState(getFilterSampleRequestFormSchema());
  const {
    control: filterFormControl,
    watch: filterFormWatch,
    getValues: filterFormGetValues,
    reset: filterFormReset
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  //State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sampleResults, setSampleResults] = useState<SampleResultTableItem[]>([]);
  const [samplesTable, setSamplesTable] = useState<DsmTableData | null>(null);
  const [samplesTablePages, setSamplesTablePages] = useState<Page[]>([]);
  const [currentSamplesTablePage, setCurrentSamplesTablePage] = useState<number>(1);
  const [phases, setPhases] = useState<DsmSelectOption[]>([]);
  const [resultFilterOptions, setResultFilterOptions] = useState<DsmSelectOption[]>([]);
  const [resultFilterTypeOptions, setResultFilterTypeOptions] = useState<DsmSelectOption[]>([]);
  const filterFormSearchQuery = filterFormWatch("searchQuery");
  const filterFormResults = filterFormWatch("results");

  useEffect(() => {
    setPageTitle(t("sample-request-list.page.title"));
    setCrumbs([{ title: t("sample-request-list.page.title"), type: routeTypes.samples }]);
    initData();
  }, []);

  useEffect(() => {
    if (isLoading) return;
    if (currentSamplesTablePage !== 1) {
      setCurrentSamplesTablePage(1);
      return;
    }
    loadSamplesTable();
  }, [filterFormSearchQuery]);

  useEffect(() => {
    if (isLoading) return;
    loadSamplesTable();
  }, [currentSamplesTablePage]);

  //Methods
  const initData = async () => {
    initFilterData();
    await loadSamplesTable();
    setIsLoading(false);
  };

  const loadSamplesTable = async () => {
    let pageToken: string | null = null;
    if (currentSamplesTablePage) {
      pageToken = samplesTablePages.find((_) => _.page === currentSamplesTablePage - 1)?.token ?? null;
    }

    const filterValues = filterFormGetValues();
    const collectionDateStart = filterValues.collectionDateStart ? dayjs(filterValues.collectionDateStart) : undefined;
    const collectionDateEnd = filterValues.collectionDateEnd ? dayjs(filterValues.collectionDateEnd) : undefined;

    const sampleResults = await getSampleResultsForCustomer(
      role === Role.Customer ? customer?.id || "" : null,
      {
        itemsPerPage: 10,
        sortDirection: SortDirection.Desc,
        pageToken,
        excludePendingResults: false,
        filters: {
          query: filterFormSearchQuery,
          collectionDate: {
            startDate: collectionDateStart && collectionDateStart.isValid() ? collectionDateStart.format("YYYY-MM-DD") : undefined,
            endDate: collectionDateEnd && collectionDateEnd.isValid() ? collectionDateEnd.format("YYYY-MM-DD") : undefined
          },
          phaseIds: filterValues.phaseIds ? [filterValues.phaseIds] : undefined,
          average: getAverageFilterValue(filterValues.results, filterValues.resultsType, filterValues.resultsValue)
        }
      },
      setToast
    );

    const samplesTableData: DsmTableData = {
      columns: [
        { id: "collectionDate", label: t("sample-request.collection-date") },
        { id: "name", label: t("sample-request.name") },
        { id: "farm", label: t("sample-request.farm") },
        { id: "phase", label: t("sample-request.phase") },
        { id: "average", label: t("sample-request.average") }
      ],
      data:
        sampleResults?.rows?.map((sampleResult) => {
          return {
            isClickable: true,
            id: sampleResult.sampleRequestId || "",
            cells: [
              { value: dayjs(sampleResult.collectionDate || "").format(t("general.date-format")) },
              { value: sampleResult.name || "" },
              { value: sampleResult.locationName || "" },
              { value: sampleResult.phaseName || "" },
              {
                badges: [
                  {
                    value: sampleResult.average
                      ? `${sampleResult.average?.toString() || ""} ${t("general.d3-unit")}`
                      : t("general.result-status.pending"),
                    variant: sampleResult.average ? "success" : "primary"
                  }
                ]
              }
            ]
          };
        }) || []
    };
    if (role === Role.Manager || role === Role.Admin) {
      samplesTableData.columns.splice(2, 0, { id: "customer", label: t("sample-request.customer") });
      sampleResults?.rows?.forEach((sampleResult, index) => {
        samplesTableData.data[index].cells.splice(2, 0, { value: sampleResult.customer?.name || "" });
      });
    }
    setSampleResults(sampleResults?.rows || []);
    setSamplesTable(samplesTableData);
    setSamplesTablePages(sampleResults?.pages || []);
  };

  const initFilterData = async () => {
    const species = await getSpecies(SpeciesGroup.Swine, setToast);
    const speciesId = species.find((_) => _.group === SpeciesGroup.Swine)?.id || "";
    setPhases(await getPhaseForSelect(speciesId, setToast));

    setResultFilterOptions([
      { text: t("general.result-status.pending"), value: ResultsFilterOption.ResultsPending },
      { text: t("sample-request.has-result"), value: ResultsFilterOption.HasResults }
    ]);

    setResultFilterTypeOptions(
      Object.keys(ComparisonType).map((comparisonType) => {
        return { value: comparisonType, text: t(`general.comparison-type.${comparisonType.toLocaleLowerCase()}`) };
      })
    );
  };

  const handleResultListPageChange = async (page: number) => {
    setCurrentSamplesTablePage(page);
  };

  const handleTableRowClick = (e: DsmTableCustomEvent<string>) => {
    if (role === Role.Manager || role === Role.Admin) {
      const sampleResult = sampleResults.find((sampleResult) => sampleResult.sampleRequestId === e.detail);
      sessionStorage.setItem("customerId", sampleResult?.customer?.id || "");
    }
    navigate(routes.farmSample.replace(":sampleID", e.detail));
  };

  const handleApplyFilters = () => {
    loadSamplesTable();
  };

  const handleClearFilters = () => {
    filterFormReset();
  };

  const getAverageFilterValue = (
    resultsFilterOption: ResultsFilterOption | undefined,
    resultsType: ComparisonType | undefined,
    resultsValue: number | undefined
  ): FloatFilter | undefined => {
    if (resultsFilterOption === ResultsFilterOption.ResultsPending) {
      return { type: ComparisonType.LT, value: 0.1 };
    }
    if (resultsFilterOption === ResultsFilterOption.HasResults) {
      if (resultsType && resultsValue) {
        return { type: resultsType, value: resultsValue };
      }

      return { type: ComparisonType.GT, value: 0.1 };
    }
    return undefined;
  };

  return (
    <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
      <PageHeader header={t("sample-request-list.page.title")} description={t("sample-request-list.page.description")}></PageHeader>
      {!isLoading ? (
        <>
          <div className="filters">
            <div className="search">
              <SearchInput fieldName="searchQuery" control={filterFormControl} placeholder={t("general.search")}></SearchInput>
            </div>
            <FilterContainer onApply={handleApplyFilters} onClear={handleClearFilters}>
              <>
                <div className="filter-row__two-items">
                  <DateInput
                    fieldName="collectionDateStart"
                    control={filterFormControl}
                    label={t("sample-request.collection-date-start")}
                  ></DateInput>
                  <DateInput
                    fieldName="collectionDateEnd"
                    control={filterFormControl}
                    label={t("sample-request.collection-date-end")}
                  ></DateInput>
                </div>
                <Select fieldName="phaseIds" control={filterFormControl} options={phases} label={t("sample-request.phase")}></Select>
                <RadioButtonGroup
                  fieldName="results"
                  control={filterFormControl}
                  options={resultFilterOptions}
                  label={t("sample-request.average")}
                ></RadioButtonGroup>
                {filterFormResults === ResultsFilterOption.HasResults ? (
                  <div className="filter-row__two-items">
                    <Select
                      fieldName="resultsType"
                      control={filterFormControl}
                      options={resultFilterTypeOptions}
                      label={t("sample-request.result-type")}
                    ></Select>
                    <NumberInput
                      fieldName="resultsValue"
                      control={filterFormControl}
                      label={t("sample-request.result-value")}
                    ></NumberInput>
                  </div>
                ) : null}
              </>
            </FilterContainer>
          </div>
          <div className="card card--table">
            {samplesTable?.data.length ? (
              <>
                <DsmTable data={samplesTable} onDsmRowClick={handleTableRowClick}></DsmTable>
                {samplesTablePages.length > 0 ? (
                  <DsmPagination
                    currentPage={currentSamplesTablePage}
                    pageCount={samplesTablePages.length}
                    onDsmChangePage={(e) => handleResultListPageChange(e.detail)}
                  ></DsmPagination>
                ) : null}
              </>
            ) : (
              <DsmEmptyState icon="charts/pie-chart-01" header={t("sample-request-list.samples-table.empty")}></DsmEmptyState>
            )}
          </div>
        </>
      ) : (
        <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator>
      )}
    </DsmGrid>
  );
}

export default SampleRequestList;
