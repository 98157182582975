import * as yup from "yup";
import i18next from "i18next";

export interface FilterCustomerForm {
  searchQuery: string;
}

export const getFilterCustomerFormSchema = () => {
  return yup
    .object({
      searchQuery: yup.string().optional().label(i18next.t("general.search"))
    })
    .required();
};
