import { useTranslation } from "react-i18next";
import PageHeader from "../../../components/pageHeader/PageHeader";
import { DsmGrid, DsmIcon, DsmButton, DsmLoadingIndicator } from "@dsm-dcs/design-system-react";
import { DsmProgressSteps } from "@dsm-dcs/design-system-react";
import styles from "./KitRequestCreate.module.scss";
import LocationStep, { LocationStepRef } from "./steps/locationStep/locationStep";
import OrderStep, { OrderStepRef } from "./steps/orderStep/orderStep";
import OrderConfirm from "./steps/orderConfirm/orderConfirm";
import { useEffect, useRef, useState } from "react";
import { useLayout } from "../../../contexts/layout.context";
import { LocationStepForm } from "../../../models/forms/location-step-form.model";
import { createKitRequest } from "../../../services/kitRequest.service";
import { useNavigate } from "react-router-dom";
import { routeTypes, routes } from "../../../routes";
import { ProgressStep } from "@dsm-dcs/design-system/dist/types/types/progress-steps";
import useBreakpoint, { Breakpoints } from "../../../hooks/breakpoint.hook";

function KitRequestCreate() {
  //Hooks
  const { t } = useTranslation();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const breakpoint = useBreakpoint();
  const navigate = useNavigate();

  //State
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [steps, setSteps] = useState<ProgressStep[]>([]);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [shouldShowOrderModal, setShouldShowOrderModal] = useState<boolean>(false);
  const locationStepRef = useRef<LocationStepRef | null>(null);
  const [locationStepForm, setLocationStepForm] = useState<LocationStepForm | null>(null);
  const [locationStepValid, setLocationStepValid] = useState<boolean>(false);
  const [address, setAddress] = useState<string[]>([]);
  const orderStepRef = useRef<OrderStepRef | null>(null);
  const [amountOfKits, setAmountOfKits] = useState<number | null>(null);
  const [orderStepValid, setOrderStepValid] = useState<boolean>(false);
  const [showOrderButton, setShowOrderButton] = useState<boolean>(true);

  //Effects
  useEffect(() => {
    setPageTitle(t("order-new-kit.page.title"));
    setCrumbs([{ title: t("order-new-kit.page.title"), type: routeTypes.order }]);
    initData();
    setShowOrderButton(process.env.REACT_APP_SHOW_ORDER_BUTTON === "true");
  }, []);

  useEffect(() => {
    setLocationStepForm(locationStepRef.current?.getFormData() || null);
    setAddress(locationStepRef.current?.getAddress() || []);
    setAmountOfKits(orderStepRef.current?.getData() || null);
  }, [activeStep, shouldShowOrderModal]);

  useEffect(() => {
    setSteps((steps) => {
      steps[0].completed = locationStepValid;
      steps[1].completed = orderStepValid;
      steps[1].disabled = !locationStepValid;
      return steps;
    });
  }, [locationStepValid, orderStepValid]);

  //Methods
  const initData = () => {
    setSteps([
      {
        id: "1",
        completed: false,
        title: t("order-new-kit.location-step.step.header"),
        description: t("order-new-kit.location-step.step.description")
      },
      {
        id: "2",
        completed: false,
        disabled: true,
        title: t("order-new-kit.order-step.step.header"),
        description: t("order-new-kit.order-step.step.description")
      }
    ]);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (activeStep === 2) {
      setShouldShowOrderModal(true);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const onSubmit = async (): Promise<void> => {
    setShouldShowOrderModal(false);
    setIsSubmitting(true);

    if (locationStepForm && amountOfKits) {
      try {
        await createKitRequest(locationStepForm, amountOfKits);
        setToast({ text: t("order-new-kit.submit-success"), type: "success" });
        navigate(routes.home);
      } catch (e: unknown) {
        const error = e as Error;
        setToast({ text: t("general.error", { action: t("order-new-kit.error-action") }), error: error.message });
        setIsSubmitting(false);
      }
    }
  };

  const getNextButtonDisabledState = (): boolean => {
    if (activeStep === 1 && !locationStepValid) {
      return true;
    } else if (activeStep === 2 && (!locationStepValid || !orderStepValid)) {
      return true;
    }
    return false;
  };

  return (
    <DsmGrid className={`main-container main-container--with-breadcrumb ${styles["grid"]}`} fixed={true} container-only={true}>
      <PageHeader header={t("order-new-kit.page.title")} description={t("order-new-kit.page.description")}></PageHeader>
      {!isSubmitting ? (
        <>
          <DsmProgressSteps
            data-testid="progress-steps"
            className={styles["progress"]}
            stacked={breakpoint === Breakpoints.xs}
            steps={steps}
            active-step={activeStep}
            onDsmStepChange={(e) => setActiveStep(Number.parseInt(e.detail))}
          ></DsmProgressSteps>
          <div className={`${styles.step} ${activeStep === 1 ? styles["step--active"] : ""}`} data-testid="step-1">
            <LocationStep ref={locationStepRef} setFormStepValid={setLocationStepValid} />
          </div>
          <div className={`${styles.step} ${activeStep === 2 ? styles["step--active"] : ""}`} data-testid="step-2">
            <OrderStep ref={orderStepRef} setFormStepValid={setOrderStepValid} />
          </div>
          <div className={styles["progress-controls"]}>
            <div>
              {activeStep > 1 ? (
                <DsmButton variant="primary" disabled={activeStep === 1} onClick={handleBack}>
                  <DsmIcon name="arrows/arrow-left"></DsmIcon>
                  {t("general.back")}
                </DsmButton>
              ) : null}
            </div>
            {activeStep !== 2 || (activeStep === 2 && showOrderButton) ? (
              <DsmButton disabled={getNextButtonDisabledState()} onClick={handleNext} variant="primary">
                {activeStep === 2 ? (
                  <DsmIcon name="finance-ecommerce/shopping-cart-01"></DsmIcon>
                ) : (
                  <DsmIcon slot="after" name="arrows/arrow-right"></DsmIcon>
                )}
                {activeStep === 2 ? t("order-new-kit.order") : t("general.next")}
              </DsmButton>
            ) : null}
          </div>
          {shouldShowOrderModal ? (
            <OrderConfirm
              address={address}
              show={shouldShowOrderModal}
              setShow={setShouldShowOrderModal}
              onSubmit={onSubmit}
              disabled={getNextButtonDisabledState()}
            />
          ) : null}
        </>
      ) : (
        <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator>
      )}
    </DsmGrid>
  );
}

export default KitRequestCreate;
