import { DsmInput } from "@dsm-dcs/design-system-react";
import { isRequired } from "../../../helpers/validation.helper";
import { Control, Controller, FieldError } from "react-hook-form";
import { AnyObject, ObjectSchema } from "yup";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  fieldName: string;
  label?: string;
  tooltip?: string;
  schema?: ObjectSchema<AnyObject>;
  fieldError?: FieldError;
  forceRequired?: boolean;
  min?: number;
  unit?: string;
};

function NumberInput({ control, fieldName, label, tooltip, schema, fieldError, forceRequired, min, unit }: Props) {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <DsmInput
          type="number"
          onDsmChange={(e) => onChange(e.detail)}
          onBlur={onBlur}
          value={value}
          min={min}
          label={label}
          tooltip={tooltip}
          invalid={!!fieldError}
          touched={!!fieldError}
          errorText={fieldError?.message}
          required={forceRequired || isRequired(schema, fieldName)}
          unit={unit}
        ></DsmInput>
      )}
    />
  );
}

export default NumberInput;
