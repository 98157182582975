import { useEffect } from "react";

function SSOLogout() {
  useEffect(() => {
    clearData();

    if (window.gigya) return;

    const script = document.createElement("script");
    script.src = "https://cdns.gigya.com/js/gigya.js?apiKey=" + process.env.REACT_APP_ADOBE_GIGYA_KEY;
    document.body.appendChild(script);
  });

  const clearData = () => {
    sessionStorage.clear();
  };

  return <div></div>;
}

export default SSOLogout;
