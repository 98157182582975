export enum HousingSwine {
  Indoor = "Indoor",
  Outdoor = "Outdoor",
  Mixed = "Mixed"
}

export enum HousingPoultry {
  Caged = "Caged",
  Aviary = "Aviary",
  Indoor = "Indoor",
  Outdoor = "Outdoor",
  Mixed = "Mixed"
}
