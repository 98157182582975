import { DsmAlert } from "@dsm-dcs/design-system-react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import AuthProvider, { AuthContext } from "../contexts/auth.context";
import "./Layout.scss";
import { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import { IBreadcrumb } from "../models/breadcrumb.model";
import { IToast } from "../models/toast.model";
import { triggerPageAnalytics } from "../helpers/analytics.helper";
import Footer from "../components/footer/Footer";

function Layout() {
  //Hooks
  const { t } = useTranslation();
  const location = useLocation();

  //State
  const [pageTitle, setPageTitle] = useState<string>("");
  const [crumbs, setCrumbs] = useState<IBreadcrumb[]>([]);
  const [toast, setToast] = useState<IToast | null>(null);
  const [timeoutID, setTimeoutID] = useState<number | null>(null);

  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast(null);
      }, 30000);
    }
  }, [toast]);

  useEffect(() => {
    //Skip location change because of code url param change
    if (new URLSearchParams(window.location.search).get("code")) return;

    //Use timeout to prevent multiple calls to analytics because of multiple dependency changes
    if (timeoutID) {
      window.clearTimeout(timeoutID);
    }
    const id = window.setTimeout(() => {
      triggerPageAnalytics(crumbs);
    }, 500);
    setTimeoutID(id);
  }, [location, crumbs]);

  return (
    <AuthProvider setToast={setToast}>
      <AuthContext.Consumer>
        {(ctx) =>
          ctx.isAuthorized ? (
            <>
              <Header title={pageTitle} setToast={setToast}></Header>
              <Breadcrumb crumbs={crumbs}></Breadcrumb>
              <main>
                <Outlet context={{ setPageTitle, setCrumbs, setToast }} />
              </main>
              <Footer></Footer>
              {toast ? (
                <DsmAlert
                  className="toast"
                  variant={toast.type || "error"}
                  header={toast.type === "success" ? t("toast.success") : t("toast.error")}
                >
                  {toast.text}
                  {toast.error ? <div className="toast__error">{toast.error}</div> : null}
                </DsmAlert>
              ) : null}
            </>
          ) : null
        }
      </AuthContext.Consumer>
    </AuthProvider>
  );
}

export default Layout;
