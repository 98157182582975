import { DsmButton, DsmIcon } from "@dsm-dcs/design-system-react";
import styles from "./NotificationList.module.scss";
import { Dispatch, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Notification, Source } from "../../models/API";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { AuthContext } from "../../contexts/auth.context";
import { updateNotificationReadStatus } from "../../services/user.service";
import { IToast } from "../../models/toast.model";

type Props = {
  setShow?: Dispatch<SetStateAction<boolean>>;
  setToast: Dispatch<SetStateAction<IToast | null>>;
  notifications?: Notification[];
};

function NotificationList({ setShow, setToast, notifications }: Props) {
  //Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customer } = useContext(AuthContext);

  //Methods
  const closeList = (): void => {
    setShow && setShow(false);
    document.querySelector("dsm-header-action")?.closeDropdown();
  };

  const getNotificationHeader = (notification: Notification): string => {
    //TODO: Should use messageType when more notification types are added
    switch (notification.source) {
      case Source.Nutrilab: {
        return t("notifications.shipped-header");
      }
      case Source.ServiceCatalogue: {
        return t("notifications.results-header");
      }
      default: {
        return "";
      }
    }
  };

  const getNotificationDescription = (notification: Notification): string => {
    //TODO: Should use messageType when more notification types are added
    switch (notification.source) {
      case Source.Nutrilab: {
        return t("notifications.shipped-description", { orderNumber: notification.description });
      }
      case Source.ServiceCatalogue: {
        return t("notifications.results-description", { name: notification.description });
      }
      default: {
        return "";
      }
    }
  };

  const getNotificationIcon = (notification: Notification): string => {
    //TODO: Should use messageType when more notification types are added
    switch (notification.source) {
      case Source.Nutrilab: {
        return "finance-ecommerce/shopping-cart-03";
      }
      case Source.ServiceCatalogue: {
        return "charts/line-chart-up-04";
      }
      default: {
        return "";
      }
    }
  };

  const handleNotificationClick = (notification: Notification): void => {
    updateNotificationReadStatus(notification.id || "", customer?.id || "", setToast);
    switch (notification.source) {
      case Source.Nutrilab: {
        navigate(routes.orderStatus);
        break;
      }
      case Source.ServiceCatalogue: {
        navigate(routes.farmSample.replace(":sampleID", notification.referenceId || ""));
        break;
      }
    }
    closeList();
  };

  return (
    <div className={styles.notifications}>
      <div className={styles["notifications__header"]}>
        <DsmIcon name="alerts-feedback/bell-01"></DsmIcon>
        <span>{t("notifications.header")}</span>
      </div>
      <div className={styles["notifications__header-mobile"]}>
        <DsmIcon name="arrows/chevron-left" size="md" onClick={closeList}></DsmIcon>
        <span>{t("notifications.header")}</span>
      </div>
      {notifications &&
        notifications.slice(0, 5).map((notification) => {
          return (
            <div className={styles["notification"]} onClick={() => handleNotificationClick(notification)} key={notification.id}>
              <DsmIcon name={getNotificationIcon(notification)} size="sm"></DsmIcon>
              <div className={styles["notification__content"]}>
                <div className={styles["notification__header"]}>{getNotificationHeader(notification)}</div>
                <div>{getNotificationDescription(notification)}</div>
              </div>
              <DsmIcon className={styles["notification__chevron"]} name="arrows/chevron-right" size="lg"></DsmIcon>
            </div>
          );
        })}
      {notifications && notifications.length === 0 && <div className={styles["description"]}>{t("notifications.none-description")}</div>}
      <DsmButton className={styles["notifications__back"]} widthFull={true} onClick={closeList}>
        {t("general.back")}
      </DsmButton>
    </div>
  );
}

export default NotificationList;
